define("codingblocks-online/pods/components/player/player-doubts-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9A6v35GI",
    "block": "{\"symbols\":[\"doubt\",\"@showTransitionButton\"],\"statements\":[[4,\"if\",[[24,[\"doubts\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"doubts\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"py-4\"],[8],[0,\"\\n      \"],[5,\"player/player-doubts-list-item\",[],[[\"@doubt\",\"@showTransitionButton\"],[[23,1,[]],[23,2,[]]]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"divider-h\"],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"t-align-c my-5\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"https://minio.codingblocks.com/amoeba/announcements-orange.svg\"],[8],[9],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"gradient-text-orange mt-3\"],[8],[0,\"No Doubts\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"white\"],[8],[0,\"\\n      Doubts that you ask appear here\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/player/player-doubts-list/template.hbs"
    }
  });

  _exports.default = _default;
});