define("codingblocks-online/pods/components/carousel-cards/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M2/1i3Cg",
    "block": "{\"symbols\":[\"cards\",\"card\"],\"statements\":[[5,\"w-async\",[],[[\"@task\",\"@autoFire\"],[[22,\"getCarouselCardsTask\"],\"true\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"border-card border-none \",[23,2,[\"theme\"]],\" white mt-5 p-lg-5 p-4\"]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"mb-4 s-50x50\"],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"src\",[29,[[23,2,[\"img\"]]]]],[10,\"alt\",\"Carousel Card\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"bold mb-4\"],[8],[1,[23,2,[\"title\"]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"font-normal mb-4\"],[8],[0,\"\\n      \"],[1,[23,2,[\"subtitle\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"button-align-container\"],[8],[0,\"\\n      \"],[7,\"a\",true],[11,\"href\",[29,[[23,2,[\"buttonLink\"]]]]],[10,\"target\",\"_blank\"],[10,\"class\",\"button-white button-solid purple\"],[8],[0,\"\\n        \"],[1,[23,2,[\"buttonText\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/carousel-cards/template.hbs"
    }
  });

  _exports.default = _default;
});