define("codingblocks-online/pods/certificate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lv3u7fVn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"iframe\",true],[11,\"src\",[24,[\"model\",\"url\"]]],[10,\"style\",\"width:100%;min-height: 600px\"],[10,\"frameborder\",\"0\"],[8],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/certificate/template.hbs"
    }
  });

  _exports.default = _default;
});