define("codingblocks-online/models/job", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    description: _emberData.default.attr(),
    location: _emberData.default.attr(),
    role: _emberData.default.attr(),
    title: _emberData.default.attr(),
    type: _emberData.default.attr(),
    eligibility: _emberData.default.attr(),
    ctc: _emberData.default.attr(),
    status: _emberData.default.attr(),
    experience: _emberData.default.attr(),
    eligible: _emberData.default.attr('boolean'),
    company: _emberData.default.belongsTo('company'),
    courses: _emberData.default.hasMany('course'),
    myApplication: _emberData.default.belongsTo('application'),
    createdAt: _emberData.default.attr(),
    deadline: _emberData.default.attr(),
    postedOn: _emberData.default.attr(),
    accepting: _emberData.default.attr('boolean'),
    form: _emberData.default.attr(),
    deadlineStr: Ember.computed('deadline', function () {
      var date = (0, _moment.default)(this.get('deadline'));
      if (date.unix() > 1e10) return 'No Deadline';
      return date.format('Do MMM YY');
    })
  });

  _exports.default = _default;
});