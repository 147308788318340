define("codingblocks-online/models/note", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    text: _emberData.default.attr(),
    duration: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    content: _emberData.default.belongsTo('content'),
    section: _emberData.default.belongsTo('section'),
    runAttempt: _emberData.default.belongsTo('run-attempt')
  });

  _exports.default = _default;
});