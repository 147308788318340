define("codingblocks-online/models/csv-submission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    score: _emberData.default.attr('number'),
    status: _emberData.default.attr(),
    outputUrl: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    csv: _emberData.default.belongsTo('csv'),
    runAttempt: _emberData.default.belongsTo('run-attempt'),
    isPending: Ember.computed.equal('status', 'pending'),
    isSuccess: Ember.computed.equal('status', 'success'),
    isErrored: Ember.computed.equal('status', 'errored')
  });

  _exports.default = _default;
});