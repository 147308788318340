define("codingblocks-online/pods/components/loading-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "99NsfF7G",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"range\",[0,8],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"c-layout-card col-12 col-sm-12 col-md-6 col-lg-3 pr-md-0\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"img-card online-card\"],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"head d-flex bg-grey skeleton-animation\"],[8],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"content d-flex flex-column justify-content-center\"],[8],[0,\"\\n                        \"],[7,\"div\",true],[10,\"class\",\"round-img-b\"],[8],[9],[0,\"\\n                        \"],[7,\"div\",true],[10,\"class\",\"rod-component\"],[8],[0,\"\\n                            \"],[7,\"div\",true],[10,\"class\",\"skeleton-lg mb-3\"],[8],[9],[0,\"\\n                            \"],[7,\"div\",true],[10,\"class\",\"skeleton-md\"],[8],[9],[0,\"\\n                        \"],[9],[0,\"\\n                    \"],[9],[0,\"\\n                \"],[9],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"foot skeleton-animation\"],[8],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"rod-component-small\"],[8],[0,\"\\n                        \"],[7,\"div\",true],[10,\"class\",\"skeleton-md\"],[8],[9],[0,\"\\n                    \"],[9],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/loading-card/template.hbs"
    }
  });

  _exports.default = _default;
});