define("codingblocks-online/helpers/format-duration", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDuration = formatDuration;
  _exports.default = void 0;

  var fixedDigits = function fixedDigits(val) {
    return ('0' + +val).slice(-2);
  };

  function formatDuration(params) {
    var duration = parseInt(params[0]);
    var options = params[1];

    if (isNaN(duration) || duration == 0) {
      return "--";
    } else {
      var res = _moment.default.duration(duration);

      var hours = Math.floor(res.asHours());
      var mins = res.minutes();
      var secs = res.seconds();

      if (options == 'humanize') {
        return (hours ? "".concat(hours, " Hours ") : '') + (mins ? "".concat(mins, " Minutes") : '');
      } else if (options == 'humanizeShort') {
        if (hours) {
          if (mins >= 15) {
            ++hours;
          }

          if (hours == 1) return "1 Hour";
          return "".concat(hours, " Hours");
        } else if (mins) {
          return mins >= 40 ? "1 Hour" : "30 Mins";
        }

        return hours ? "".concat(hours) : '';
      } else {
        hours = fixedDigits(hours);
        mins = fixedDigits(mins);
        secs = fixedDigits(secs);
        return "".concat(hours, ":").concat(mins, ":").concat(secs);
      }
    }
  }

  var _default = Ember.Helper.helper(formatDuration);

  _exports.default = _default;
});