define("codingblocks-online/pods/nagarro/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    actions: {
      goToRecommendedCourses: function goToRecommendedCourses() {
        window.scrollTo({
          top: document.getElementById('recommended-courses').offsetTop - 70,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  });

  _exports.default = _default;
});