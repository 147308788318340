define("codingblocks-online/pods/attempt/content/quiz/attempt/done/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    totalCorrect: Ember.computed('quizAttempt.result', function () {
      var result = this.get('quizAttempt.result');
      return result.questions.reduce(function (acc, question) {
        return acc + +question.correctlyAnswered.length;
      }, 0);
    }),
    totalIncorrect: Ember.computed('quizAttempt.result', function () {
      var result = this.get('quizAttempt.result');
      return result.questions.reduce(function (acc, question) {
        return acc + +question.incorrectlyAnswered.length;
      }, 0);
    })
  });

  _exports.default = _default;
});