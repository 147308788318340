define("codingblocks-online/pods/spin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gxam5CBU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"layout\",\"outsideContainer\"]]],null,{\"statements\":[[4,\"in-element\",[[28,\"-clear-element\",[[24,[\"layout\",\"outsideContainer\"]]],null]],[[\"guid\",\"guid\",\"nextSibling\"],[\"%cursor:0%\",\"%cursor:0%\",null]],{\"statements\":[[0,\"      \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/spin/template.hbs"
    }
  });

  _exports.default = _default;
});