define("codingblocks-online/pods/components/code-challenge/code-challenge-solution/code-challenge-solution-testcases/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ekWvQbCI",
    "block": "{\"symbols\":[\"testcase\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row justify-content-between no-gutters\"],[8],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    Test Cases:\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grey\"],[8],[0,\"\\n    \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],\"onClose\"]],[8],[0,\"X\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"testcases\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row pt-3 pb-3 no-gutters\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-2\"],[8],[0,\"\\n      Test Case \"],[1,[28,\"inc\",[[23,2,[]],1],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-4 t-align-r\"],[8],[0,\"\\n      \"],[7,\"a\",true],[10,\"class\",\"red\"],[11,\"href\",[29,[[23,1,[\"input\"]]]]],[10,\"download\",\"\"],[8],[7,\"i\",true],[10,\"class\",\"fas fa-arrow-down\"],[8],[9],[0,\" Download Input\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-4 t-align-r\"],[8],[0,\"\\n      \"],[7,\"a\",true],[10,\"class\",\"red\"],[11,\"href\",[29,[[23,1,[\"expectedOutput\"]]]]],[10,\"download\",\"\"],[8],[7,\"i\",true],[10,\"class\",\"fas fa-arrow-down\"],[8],[9],[0,\" Download Output\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"divider-h\"],[8],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/code-challenge/code-challenge-solution/code-challenge-solution-testcases/template.hbs"
    }
  });

  _exports.default = _default;
});