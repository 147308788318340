define("codingblocks-online/models/webinar", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    webinar_id: _emberData.default.attr(),
    name: _emberData.default.attr(),
    description: _emberData.default.attr(),
    start_time: _emberData.default.attr('date'),
    duration: _emberData.default.attr('number'),
    start_url: _emberData.default.attr(),
    join_url: _emberData.default.attr(),
    content: _emberData.default.belongsTo('content'),
    contentId: _emberData.default.attr()
  });

  _exports.default = _default;
});