define("codingblocks-online/pods/components/course-search-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yMyQGMyT",
    "block": "{\"symbols\":[\"result\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"input-search dsp-none-sm bg-black-grey p-3\"],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"type\",\"class\",\"placeholder\",\"key-press\",\"focusIn\",\"focusOut\"],[\"text\",\"bg-black-grey w-75 grey\",\"Search Content\",[28,\"perform\",[[24,[\"searchTask\"]]],null],[28,\"action\",[[23,0,[]],\"showResult\"],null],[28,\"action\",[[23,0,[]],\"hideResult\"],null]]]],false],[0,\"\\n  \"],[7,\"img\",true],[10,\"src\",\"https://minio.codingblocks.com/amoeba/search-icon.svg\"],[10,\"alt\",\"Search Icon\"],[10,\"class\",\"float-right\"],[8],[9],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"hideResultsBox\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"search-box bg-black-grey br-15 inverted\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"searchTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"        \"],[1,[22,\"loader-component\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"results\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",false],[12,\"class\",\"row search-content pointer align-items-center\"],[3,\"action\",[[23,0,[]],\"transitionToContent\",[23,1,[\"contentId\"]],[23,1,[\"sectionId\"]]]],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"img-container col-md-3\"],[8],[0,\"\\n              \"],[7,\"div\",true],[11,\"class\",[29,[\"content-row big \",[23,1,[\"iconClass\"]]]]],[8],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"heading-container col-md-9\"],[8],[0,\"\\n              \"],[7,\"div\",true],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n              \"],[7,\"div\",true],[10,\"class\",\"font-sm\"],[8],[1,[23,1,[\"section\"]],false],[9],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/course-search-box/template.hbs"
    }
  });

  _exports.default = _default;
});