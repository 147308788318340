define("codingblocks-online/pods/components/view-submissions-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    source: Ember.computed('submission.source', function () {
      return window.atob(this.get('submission.source') || '');
    })
  });

  _exports.default = _default;
});