define("codingblocks-online/pods/error_sometthing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i0EkN93v",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[10,\"style\",\"text-align: center; margin-top:20px\"],[8],[0,\"\\n  \"],[1,[22,\"model\"],true],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/error_sometthing/template.hbs"
    }
  });

  _exports.default = _default;
});