define("codingblocks-online/models/career-track-product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    subscriptionDuration: _emberData.default.attr(),
    productId: _emberData.default.attr(),
    careerTrack: _emberData.default.belongsTo('career-track')
  });

  _exports.default = _default;
});