define("codingblocks-online/pods/components/feedback-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L736vbYh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"feedback\",\"hasDirtyAttributes\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"textarea\",null,[[\"value\",\"placeholder\"],[[24,[\"feedback\",\"text\"]],\"Enter Feedback\"]]],false],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"saveFeedbackTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"button-solid\"],[8],[0,\"\\n    \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],[24,[\"saveFeedback\"]]]],[8],[0,\"Save\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"button-solid\"],[8],[0,\"\\n    \"],[7,\"button\",true],[10,\"disabled\",\"\"],[8],[0,\"Saving...\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"h3\",true],[8],[0,\"Your feedback:\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[1,[24,[\"feedback\",\"text\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"button-solid\"],[8],[0,\"\\n    \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],[24,[\"changeToEditMode\"]]]],[8],[0,\" Edit \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/feedback-component/template.hbs"
    }
  });

  _exports.default = _default;
});