define("codingblocks-online/pods/components/review-carousel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lmL6na8c",
    "block": "{\"symbols\":[\"rating\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row rating-carousel\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"ratingsArray\"]]],null,{\"statements\":[[0,\"        \\n          \"],[7,\"div\",true],[10,\"class\",\"col-8 col-md-4 col-lg-3\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"border-card round c-rating-card\"],[8],[0,\"\\n              \"],[7,\"div\",true],[10,\"class\",\"row justify-content-between no-gutters\"],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"bold font-sm\"],[8],[1,[23,1,[\"user\",\"firstname\"]],false],[0,\" \"],[1,[23,1,[\"user\",\"lastname\"]],false],[9],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"grey card-md\"],[8],[1,[28,\"moment-from\",[[23,1,[\"updatedAt\"]]],null],false],[9],[0,\"\\n              \"],[9],[0,\"\\n\\n              \"],[7,\"div\",true],[10,\"class\",\"heading text-ellipses\"],[8],[0,\"\\n                \"],[1,[23,1,[\"heading\"]],false],[0,\"\\n              \"],[9],[0,\"\\n\\n              \"],[1,[28,\"rating-stars-static\",null,[[\"rating\"],[[23,1,[\"value\"]]]]],false],[0,\"\\n\\n              \"],[7,\"div\",true],[10,\"class\",\"review grey\"],[8],[0,\"\\n                \"],[1,[23,1,[\"review\"]],false],[0,\"\\n              \"],[9],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"meta\",\"nextOffset\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"row justify-content-center align-items-center\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"button-dashed\"],[3,\"action\",[[23,0,[]],\"fetchRatings\"]],[8],[0,\"Load More Reviews\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/review-carousel/template.hbs"
    }
  });

  _exports.default = _default;
});