define("codingblocks-online/pods/attempt/content/quiz/view/quiz-attempt/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      q: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var quiz = this.modelFor('attempt.content.quiz.view').quiz;
      return Ember.RSVP.hash({
        question: this.store.findRecord('question', quiz.get('questions').objectAt(params.q - 1).id),
        quizAttempt: this.store.findRecord('quiz_attempt', params.viewQuizAttemptId),
        quiz: quiz
      });
    },
    setupController: function setupController(controller, model) {
      controller.set("question", model.question);
      controller.set("quizAttempt", model.quizAttempt);
      controller.set("quiz", model.quiz);
    }
  });

  _exports.default = _default;
});