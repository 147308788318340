define("codingblocks-online/pods/components/rating-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0dtul9Hx",
    "block": "{\"symbols\":[\"index\",\"@emptyClass\",\"@filledClass\",\"@changeRating\",\"@rating\",\"@scale\"],\"statements\":[[4,\"each\",[[28,\"range\",[1,[28,\"inc\",[[23,6,[]]],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[28,\"if\",[[28,\"lte\",[[23,1,[]],[23,0,[\"hoverRating\"]]],null],[23,3,[]],[23,2,[]]],null]],[11,\"onMouseEnter\",[28,\"fn\",[[28,\"mut\",[[23,0,[\"hoverRating\"]]],null],[23,1,[]]],null]],[11,\"onClick\",[28,\"fn\",[[23,4,[]],[23,1,[]]],null]],[11,\"onMouseLeave\",[28,\"fn\",[[28,\"mut\",[[23,0,[\"hoverRating\"]]],null],[23,5,[]]],null]],[10,\"role\",\"button\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"lte\",[[23,1,[]],[23,0,[\"hoverRating\"]]],null]],null,{\"statements\":[[0,\"      \"],[5,\"rating-bar/filled-star\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"rating-bar/empty-star\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/rating-bar/template.hbs"
    }
  });

  _exports.default = _default;
});