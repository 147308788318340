define("codingblocks-online/utils/firebase", ["exports", "codingblocks-online/config/environment", "@firebase/app", "@firebase/database"], function (_exports, _environment, _app, _database) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getRef = void 0;
  var _env$firebase = _environment.default.firebase,
      apiKey = _env$firebase.apiKey,
      databaseURL = _env$firebase.databaseURL,
      projectId = _env$firebase.projectId;
  var config = {
    apiKey: apiKey,
    databaseURL: databaseURL,
    projectId: projectId
  };

  _app.default.initializeApp(config);

  var getRef = function getRef(ref) {
    return _app.default.database().ref().child(ref);
  };

  _exports.getRef = getRef;
});