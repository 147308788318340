define("codingblocks-online/helpers/format-video-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.multiply = multiply;
  _exports.default = void 0;

  function multiply(params
  /*, hash*/
  ) {
    var fl = Math.floor;
    var seconds = fl(params[0]);
    var hrs = fl(seconds / 3600);
    var mins = fl(seconds / 60);
    var sec = seconds % 60;
    sec = sec > 10 ? sec : '0' + sec;
    return (hrs ? "".concat(hrs, ":") : '') + "".concat(mins, ":").concat(sec);
  }

  var _default = Ember.Helper.helper(multiply);

  _exports.default = _default;
});