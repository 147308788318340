define("codingblocks-online/initializers/register-katex-showdown", ["exports", "showdown"], function (_exports, _showdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    // register showdownKatex as "katex" extension
    _showdown.default.extension("katex", showdownKatex());
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});