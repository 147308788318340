define("codingblocks-online/pods/components/run-request-button/component", ["exports", "codingblocks-online/utils/session"], function (_exports, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    request: Ember.computed.alias('run.runRequests.firstObject'),
    isPending: Ember.computed.equal('request.status', 'PENDING'),
    isDeclined: Ember.computed.equal('request.status', 'DECLINED'),
    isAccepted: Ember.computed.equal('request.status', 'ACCEPTED'),
    actions: {
      addRequest: function addRequest() {
        // if user is not loggedIn; have him logged In
        if (!this.get('session.isAuthenticated')) {
          return (0, _session.forceLogin)();
        }

        var request = this.store.createRecord('run-request', {
          run: this.run,
          user: this.get('currentUser.user')
        });
        request.save();
      }
    }
  });

  _exports.default = _default;
});