define("codingblocks-online/pods/attempt/content/quiz/view/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        attempts: this.store.query('quiz_attempt', {
          filter: {
            qnaId: this.modelFor('attempt.content.quiz').payload.id
          },
          sort: '-createdAt'
        }),
        quiz: this.modelFor('attempt.content.quiz').quiz
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('attempts', model.attempts);
      controller.set('quiz', model.quiz);
    }
  });

  _exports.default = _default;
});