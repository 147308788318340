define("codingblocks-online/models/question", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr(),
    description: _emberData.default.attr(),
    difficulty: _emberData.default.attr(),
    choices: _emberData.default.hasMany('choice')
  });

  _exports.default = _default;
});