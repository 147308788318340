define("codingblocks-online/pods/components/editor-collab-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/r/QThbF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isCollaborating\"]]],null,{\"statements\":[[0,\"    You are now working in Collaboration Mode \\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Do you want to switch to collaborate mode?\\n\"]],\"parameters\":[]}],[0,\"  \"],[7,\"button\",false],[12,\"class\",\"red ml-2\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showHelpModal\"]]],null],true]],[8],[0,\"Learn More\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"x-toggle\",[],[[\"@showLabels\",\"@offLabel\",\"@onLabel\",\"@value\",\"@onToggle\",\"@disabled\"],[true,\"Normal Mode\",\"Collaborate Mode\",[23,0,[\"isCollaborating\"]],[28,\"action\",[[23,0,[]],[24,[\"onChange\"]]],null],[23,0,[\"onChange\",\"isRunning\"]]]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showHelpModal\"]]],null,{\"statements\":[[0,\"  \"],[5,\"editor-collab-help\",[],[[\"@onClose\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showHelpModal\"]]],null],false],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/editor-collab-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});