define("codingblocks-online/pods/components/my-courses-list/wishlisted-courses/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wv5wdV1y",
    "block": "{\"symbols\":[\"row\",\"@wishlist\"],\"statements\":[[4,\"each\",[[23,2,[]]],null,{\"statements\":[[5,\"my-courses-list/course-row\",[],[[\"@course\"],[[23,1,[\"course\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-lg-4 col-md-6 pl-md-3 mt-md-0 mt-4\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"mb-2\"],[8],[0,\"Students Rating\"],[9],[0,\"\\n    \"],[5,\"rating-stars-static\",[],[[\"@rating\",\"@tagName\"],[[23,1,[\"course\",\"rating\"]],\"span\"]]],[0,\"\\n    \"],[1,[23,1,[\"course\",\"rating\"]],false],[0,\", \"],[1,[23,1,[\"course\",\"reviewCount\"]],false],[0,\" ratings\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-lg-4 col-12 mt-lg-0 mt-4 pl-lg-3\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row no-gutters justify-content-around align-items-center\"],[8],[0,\"\\n      \"],[7,\"button\",true],[10,\"class\",\"button-dashed button-orange mr-3\"],[8],[0,\"\\n        Start Free Trial\\n      \"],[9],[0,\"\\n      \"],[7,\"a\",true],[10,\"href\",\"/courses/\"],[10,\"class\",\"button-solid button-orange\"],[8],[0,\"\\n        Buy now\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/my-courses-list/wishlisted-courses/template.hbs"
    }
  });

  _exports.default = _default;
});