define("codingblocks-online/pods/attempt/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "na68kP2C",
    "block": "{\"symbols\":[\"progress\"],\"statements\":[[4,\"if\",[[24,[\"deadlineBlocker\"]]],null,{\"statements\":[[5,\"blockers/section-not-started\",[],[[\"@section\"],[[22,\"activeSection\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"model\",\"payload\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[5,\"w-async\",[],[[\"@promise\"],[[23,0,[\"model\",\"progress\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"content-feedback\",[],[[\"@progress\"],[[23,1,[]]]]],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"unless\",[[28,\"eq\",[[24,[\"model\",\"contentable\"]],\"course-recommend\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"d-lg-block d-none\"],[8],[0,\"\\n    \"],[5,\"player/player-tabs\",[],[[],[]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"run\",\"isStarted\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"unstarted-content\",[],[[\"@run\"],[[22,\"run\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"runAttempt\",\"paused\"]]],null,{\"statements\":[[0,\"  \"],[5,\"blockers/paused-run-attempt\",[],[[\"@runAttempt\"],[[22,\"runAttempt\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"locked-content\",[],[[\"@course\"],[[24,[\"run\",\"course\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/attempt/content/template.hbs"
    }
  });

  _exports.default = _default;
});