define("codingblocks-online/models/comment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    body: _emberData.default.attr(),
    name: _emberData.default.attr(),
    avatarTemplate: _emberData.default.attr(),
    username: _emberData.default.attr(),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    doubt: _emberData.default.belongsTo('doubt'),
    discourseTopicId: _emberData.default.attr()
  });

  _exports.default = _default;
});