define("codingblocks-online/pods/components/pagination-pills/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2hqpwk9Q",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"t-align-c bold\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"d-none-disabled\"],[12,\"disabled\",[28,\"eq\",[[24,[\"pagination\",\"currentPage\"]],1],null]],[3,\"action\",[[23,0,[]],[24,[\"setOffset\"]],[24,[\"pagination\",\"prevOffset\"]]]],[8],[0,\"\\n    < Prev\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"dark-grey mx-4\"],[8],[0,\"\\n    \"],[1,[28,\"or\",[[28,\"inc\",[[24,[\"pagination\",\"currentOffset\"]]],null],1],null],false],[0,\" - \"],[1,[28,\"or\",[[24,[\"pagination\",\"nextOffset\"]],[24,[\"pagination\",\"count\"]]],null],false],[0,\" of \"],[1,[24,[\"pagination\",\"count\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \\n  \"],[7,\"button\",false],[12,\"class\",\"d-none-disabled\"],[12,\"disabled\",[28,\"not\",[[24,[\"pagination\",\"nextOffset\"]]],null]],[3,\"action\",[[23,0,[]],[24,[\"setOffset\"]],[24,[\"pagination\",\"nextOffset\"]]]],[8],[0,\"\\n    Next >\\n  \"],[9],[0,\"\\n  \\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/pagination-pills/template.hbs"
    }
  });

  _exports.default = _default;
});