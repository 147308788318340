define("codingblocks-online/models/career-track", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: _emberData.default.attr(),
    description: _emberData.default.attr(),
    slug: _emberData.default.attr(),
    languages: _emberData.default.attr(),
    unlisted: _emberData.default.attr(),
    logo: _emberData.default.attr(),
    status: _emberData.default.attr(),
    background: _emberData.default.attr(),
    courses: _emberData.default.hasMany('course'),
    courseCount: Ember.computed('courses', function () {
      return this.hasMany('courses').ids().length;
    }),
    careerTrackProducts: _emberData.default.hasMany('career-track-product'),
    runs: _emberData.default.hasMany('run')
  });

  _exports.default = _default;
});