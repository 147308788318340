define("codingblocks-online/data/course-dashboard", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "codingblocks-online/data/buttons"], function (_exports, _toConsumableArray2, _buttons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _arguments = arguments;

  var ifElementExists = function ifElementExists(id) {
    return function () {
      return !!document.getElementById(id);
    };
  };

  var onMobile = window.innerWidth <= 800;

  var and = function and() {
    return (0, _toConsumableArray2.default)(_arguments).reduce(function (acc, fn) {
      return acc && fn();
    }, true);
  };

  var _default = [{
    attachTo: {
      element: '#course-information-card',
      on: 'left'
    },
    buttons: [_buttons.default.cancel, _buttons.default.next],
    canClickTarget: false,
    title: 'Welcome to the new course Dashboard',
    text: "\n      <p> This is your course dashboard. \n          You can glance over your progress, track milestones and find all necessary information regarding the course here.\n      </p>\n    "
  }, {
    attachTo: {
      element: '#goodies-lock',
      on: onMobile ? 'bottom' : 'right'
    },
    buttons: [_buttons.default.back, _buttons.default.next],
    showOn: ifElementExists('goodies-lock'),
    title: 'Complete your course - Goodies',
    text: "\n      <p> \n        Complete the course to be unlock free goodies!\n      </p>\n    ",
    highlightClass: 'px-3'
  }, {
    attachTo: {
      element: '#certificate-lock',
      on: onMobile ? 'bottom' : 'right'
    },
    buttons: [_buttons.default.back, _buttons.default.next],
    showOn: ifElementExists('certificate-lock'),
    title: 'Complete your course - Certification',
    text: "\n      <p> \n        Let's get certified! Unlock your certificate from here\n      </p>\n    ",
    highlightClass: 'px-3'
  }, {
    attachTo: {
      element: '#join-whatsapp-card',
      on: onMobile ? 'bottom' : 'right'
    },
    buttons: [_buttons.default.back, _buttons.default.next],
    showOn: ifElementExists('join-whatsapp-card'),
    title: 'Join the Conversation!',
    text: "\n      <p> \n        Tap here join the whatsapp group for this batch.\n      </p>\n    ",
    highlightClass: 'border-radius-none'
  }, {
    attachTo: {
      element: '#course-library',
      on: 'top'
    },
    buttons: [_buttons.default.back, _buttons.default.next],
    title: 'This is the course library',
    text: "\n      <p> Click here to manage all your Notes, Bookmarks, Announcements and Doubts at once. </p>\n    "
  }, {
    attachTo: {
      element: '#performance-stats',
      on: 'top'
    },
    buttons: [_buttons.default.back, _buttons.default.next],
    title: 'These are your stats!',
    text: "\n      <p> Take a look at your all-time course progress from here </p>\n    "
  }, {
    attachTo: {
      element: '#course-intro',
      on: 'left'
    },
    buttons: [_buttons.default.back, _buttons.default.next],
    title: 'Read Me!',
    text: "\n      <p>\n       Read on to find out what you are going to learn in this course.\n      </p>\n    "
  }, {
    attachTo: {
      element: '#start-course-dashboard-tour',
      on: onMobile ? 'bottom' : 'right'
    },
    buttons: [_buttons.default.cancel],
    title: 'Restart Tour',
    highlightClass: 'p-3',
    text: "\n      <p>\n       Click here to restart this tour anytime.\n      </p>\n    "
  }];
  _exports.default = _default;
});