define("codingblocks-online/utils/testcases", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isValidResult = _exports.isPassedTestcase = void 0;

  var isPassedTestcase = function isPassedTestcase(tc) {
    return ["success", "correct"].includes(tc.result);
  };

  _exports.isPassedTestcase = isPassedTestcase;

  var isValidResult = function isValidResult(result) {
    return result.data && result.data.testcases;
  };

  _exports.isValidResult = isValidResult;
});