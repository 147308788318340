define("codingblocks-online/pods/feedback/doubt/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HCi60Ht4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"w-50 mx-auto bg-dark-grey\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"doubt-card inverted\"],[8],[0,\"\\n    \"],[5,\"doubt-feedback\",[],[[\"@doubt\",\"@onSave\"],[[22,\"model\"],[28,\"action\",[[23,0,[]],\"sayThankYou\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/feedback/doubt/template.hbs"
    }
  });

  _exports.default = _default;
});