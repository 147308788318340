define("codingblocks-online/pods/components/review-pad/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+E3zVYJ0",
    "block": "{\"symbols\":[\"question\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"c-review-pad\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n    Review Questions\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"options\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"questions\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",[29,[\"circle \",[28,\"get\",[[24,[\"circleClassNames\"]],[23,2,[]]],null]]]],[3,\"action\",[[23,0,[]],[24,[\"onCircleClick\"]],[28,\"inc\",[[23,2,[]],1],null]]],[8],[0,\"\\n      \"],[1,[28,\"inc\",[[23,2,[]],1],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/review-pad/template.hbs"
    }
  });

  _exports.default = _default;
});