define("codingblocks-online/pods/components/my-courses-list/course-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uYTJKqyz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row no-gutters align-items-center bg-background-main-3 br-10 p-4 my-3\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-lg-4 col-6\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row no-gutters align-items-center\"],[8],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"class\",\"s-60x60 br-5\"],[11,\"src\",[24,[\"course\",\"logo\"]]],[11,\"alt\",[24,[\"course\",\"title\"]]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"flex-1 pl-4\"],[8],[0,\"\\n        \"],[7,\"h5\",true],[10,\"class\",\"bold\"],[8],[1,[24,[\"course\",\"title\"]],false],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"font-normal mt-2\"],[8],[0,\"Mentor: \"],[1,[22,\"instrcutorNames\"],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[14,1,[[24,[\"course\"]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/my-courses-list/course-row/template.hbs"
    }
  });

  _exports.default = _default;
});