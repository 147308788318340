define("codingblocks-online/pods/components/mentor-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "605FaKFo",
    "block": "{\"symbols\":[\"instructor\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"border-card border-none px-0 pb-0 py-lg-5 py-4\"],[10,\"style\",\"background: linear-gradient(180deg, #BB6BD9 0%, #7F39AD 100%);\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"bold px-lg-5 px-4 mb-4\"],[8],[0,\"Mentors\"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"instructors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row no-gutters align-items-center hover-grey py-4 px-lg-5 px-4\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"mr-2\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"class\",\"round s-50x50 br-25 overflow-hidden\"],[11,\"src\",[29,[[23,1,[\"photo\"]]]]],[10,\"alt\",\"\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex-1 ml-4 ml-md-2\"],[8],[0,\"\\n      \"],[7,\"h5\",true],[10,\"class\",\"bold mb-1\"],[8],[0,\"\\n        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"a\",true],[11,\"href\",[29,[\"mailto:\",[23,1,[\"email\"]]]]],[8],[0,\"\\n        Send Email\"],[7,\"i\",true],[10,\"class\",\"ml-2 far fa-envelope\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/mentor-card/template.hbs"
    }
  });

  _exports.default = _default;
});