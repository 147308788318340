define("codingblocks-online/pods/login-blocker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "85xw3nbF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row font-mds my-5\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-10 col-sm-6 mx-auto t-align-c\"],[8],[0,\"\\n    You are already logged in from somewhere else. \"],[7,\"br\",true],[8],[9],[0,\"\\n    We only allow one session per user, please logout from other devices and try again.\\n    \\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[7,\"div\",true],[10,\"class\",\"d-flex all-center my-5\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"button-solid button-orange\"],[3,\"action\",[[23,0,[]],\"logoutFromAll\"]],[8],[0,\"Logout from all devices and Continue\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/login-blocker/template.hbs"
    }
  });

  _exports.default = _default;
});