define("codingblocks-online/pods/components/file-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E9wChwuQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"d-flex justify-content-between align-items-center\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"link\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[7,\"a\",true],[11,\"href\",[29,[[22,\"link\"]]]],[10,\"class\",\"white\"],[10,\"download\",\"\"],[8],[0,\"Download Submission\"],[9],[0,\"\\n      \"],[7,\"i\",false],[12,\"class\",\"fa fa-times\"],[3,\"action\",[[23,0,[]],\"reset\"]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"bold mr-3\"],[8],[0,\"\\n        Upload Solution:\\n      \"],[9],[0,\"\\n      \"],[1,[28,\"file-upload-minio\",null,[[\"triggerUpload\",\"onComplete\",\"onError\"],[[24,[\"triggerUpload\"]],[28,\"action\",[[23,0,[]],\"uploaded\"],null],[28,\"action\",[[23,0,[]],\"uploadFailed\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"float-right\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"button-solid button-orange\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"triggerUpload\"]]],null],true]],[8],[0,\"\\n        Upload CSV\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/file-input/template.hbs"
    }
  });

  _exports.default = _default;
});