define("codingblocks-online/pods/components/player/player-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g+LmzOva",
    "block": "{\"symbols\":[\"notes\",\"doubts\"],\"statements\":[[5,\"player/player-tabs/doubt-support-strip\",[],[[\"@runAttempt\",\"@onAskDoubt\"],[[22,\"runAttempt\"],[28,\"action\",[[23,0,[]],\"openAskDoubtModal\"],null]]]],[0,\"\\n\"],[5,\"w-tab-nav\",[[12,\"class\",\"c-player\"]],[[\"@tabClass\",\"@inactiveClass\",\"@tabs\",\"@activeTab\",\"@onTabChange\"],[\"inverted\",\"white\",[22,\"tabs\"],[22,\"activeTab\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"activeTab\"]]],null]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"activeTab\",\"name\"]],\"Doubts\"],null]],null,{\"statements\":[[0,\"    \"],[5,\"w-async\",[],[[\"@task\",\"@autoFire\"],[[24,[\"activeTab\",\"task\"]],\"true\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"component\",[[24,[\"activeTab\",\"component\"]]],[[\"doubts\"],[[23,2,[]]]]],false],[0,\"  \\n    \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"activeTab\",\"name\"]],\"Notes\"],null]],null,{\"statements\":[[0,\"    \"],[5,\"w-async\",[],[[\"@task\",\"@autoFire\"],[[24,[\"activeTab\",\"task\"]],\"true\"]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"component\",[[24,[\"activeTab\",\"component\"]]],[[\"onAfterSave\",\"notes\"],[[28,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"activeTab\",\"task\"]]],null]],null],[23,1,[]]]]],false],[0,\"  \\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showAskDoubtModal\"]]],null,{\"statements\":[[0,\"  \"],[5,\"player/player-ask-doubt-modal\",[[12,\"class\",\"row white h-100\"]],[[\"@onClose\",\"@onAfterSave\",\"@doubt\"],[[28,\"action\",[[23,0,[]],\"closeAskDoubtModal\"],null],[28,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"fetchDoubtsTask\"]]],null]],null],[22,\"newDoubt\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/player/player-tabs/template.hbs"
    }
  });

  _exports.default = _default;
});