define("codingblocks-online/utils/browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPublicUrl = void 0;

  var getPublicUrl = function getPublicUrl() {
    if (window.location.port) {
      return "".concat(window.location.protocol, "//").concat(window.location.hostname, ":").concat(window.location.port, "/app/");
    }

    return "".concat(window.location.protocol, "//").concat(window.location.hostname, "/app/");
  };

  _exports.getPublicUrl = getPublicUrl;
});