define("codingblocks-online/pods/attempt/content/quiz/attempt/done/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Eh9DHak",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"p-3 c-qna\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"bold\"],[8],[0,\"\\n    \"],[1,[24,[\"quiz\",\"title\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"font-sm mt-4\"],[8],[0,\"\\n    Your Scorecard\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row mt-4\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-4 col-lg-3\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"font-mdxl bold\"],[8],[1,[24,[\"quiz\",\"questions\",\"length\"]],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"font-md bold\"],[8],[0,\"Total Questions\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-4 col-lg-3 green\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"font-mdxl bold\"],[8],[1,[22,\"totalCorrect\"],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"font-md bold\"],[8],[0,\"Right Answers\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-4 col-lg-3 red\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"font-mdxl bold\"],[8],[1,[22,\"totalIncorrect\"],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"font-md bold\"],[8],[0,\"Wrong Answers\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"mt-5\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"activeClass\",\"class\",\"route\"],[\"\",\"button-solid button-orange\",\"attempt.content\"]],{\"statements\":[[0,\" Go Back \"]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/attempt/content/quiz/attempt/done/template.hbs"
    }
  });

  _exports.default = _default;
});