define("codingblocks-online/pods/components/blockers/section-not-started/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9DkZD4SU",
    "block": "{\"symbols\":[\"@section\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"p-5\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[5,\"fa-icon\",[],[[\"@icon\",\"@size\"],[\"lock\",\"3\"]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mx-auto\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"my-3 t-align-c\"],[8],[0,\"This section has not started yet!\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"mt-3  t-align-c\"],[8],[0,\"Section will start  on \"],[1,[28,\"moment-format\",[[23,1,[\"sectionStartDate\"]],\"MMM Do YYYY\"],null],false],[0,\".\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/blockers/section-not-started/template.hbs"
    }
  });

  _exports.default = _default;
});