define("codingblocks-online/pods/attempt/content/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    model: function model() {
      var content = this.modelFor('attempt.content');

      switch (content.contentable) {
        case 'code-challenge':
          return this.transitionTo('attempt.content.code-challenge');

        case 'csv':
          return this.transitionTo('attempt.content.csv');

        case 'document':
          return this.transitionTo('attempt.content.document');

        case 'lecture':
          return this.transitionTo('attempt.content.lecture');

        case 'video':
          return this.transitionTo('attempt.content.video');

        case 'qna':
          return this.transitionTo('attempt.content.quiz', content.get('payload.qId'));

        case 'webinar':
          return this.transitionTo('attempt.content.webinar');

        case 'course-recommend':
          return this.transitionTo('attempt.content.course-recommend');

        case 'web-challenge':
          return this.transitionTo('attempt.content.web-challenge');
      }
    }
  });

  _exports.default = _default;
});