define("codingblocks-online/utils/functional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.once = _exports.not = _exports.pipe = void 0;

  /* Thanks to ~senpai~ :) */
  var pipe = function pipe() {
    return function (param) {
      return arguments.reduce(function (acc, fn) {
        return fn(acc);
      }, param);
    };
  };

  _exports.pipe = pipe;

  var not = function not(fn) {
    return function () {
      return !fn.apply(void 0, arguments);
    };
  };

  _exports.not = not;

  var once = function once(fn) {
    var ran = false;
    return function () {
      return ran ? function () {} : (ran = true, fn.apply(void 0, arguments));
    };
  };

  _exports.once = once;
});