define("codingblocks-online/helpers/embed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getEmbeded = getEmbeded;
  _exports.default = void 0;

  function getEmbeded(param) {
    if (param[0]) {
      return param[0].replace('watch?v=', 'embed/').concat('?autoplay=1&rel=0&enablejsapi=1');
    } else return null;
  }

  var _default = Ember.Helper.helper(getEmbeded);

  _exports.default = _default;
});