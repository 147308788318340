define("codingblocks-online/pods/components/csv/csv-submission-success/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pr1ka5rI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"submission\",\"isPending\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"border-card bg-gradient-yellow white border-none\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row mx-0 justify-content-between align-items-center\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"font-xl\"],[8],[0,\"Calculating\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"submission\",\"isErrored\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"border-card bg-gradient-orange white border-none\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row mx-0 justify-content-between align-items-center\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"font-xl\"],[8],[0,\"Errored !\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"border-card bg-gradient-orange white border-none\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row mx-0 justify-content-between align-items-center\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"font-xl\"],[8],[0,\"You Answer's Accuracy\"],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"float-right font-xxl extra-bold\"],[8],[0,\"\\n        \"],[1,[24,[\"submission\",\"score\"]],false],[0,\"%\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/csv/csv-submission-success/template.hbs"
    }
  });

  _exports.default = _default;
});