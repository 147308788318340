define("codingblocks-online/pods/courses/index-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "00IAYb69",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"t-align-c mt-5 mb-5\"],[8],[0,\"\\n    All Courses\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"loading-card\",[[12,\"class\",\"w-100\"]],[[],[]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/courses/index-loading/template.hbs"
    }
  });

  _exports.default = _default;
});