define("codingblocks-online/models/section", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    premium: _emberData.default.attr(),
    totalContents: Ember.computed("contents.@each", function () {
      return this.get('contents.length');
    }),
    duration: Ember.computed("contents.@each", function () {
      return this.contents.reduce(function (acc, curr) {
        return acc + curr.get('duration');
      }, 0);
    }),
    doneContents: Ember.computed("contents.@each.isDone", function () {
      return this.contents.filter(function (content) {
        return content.get("isDone");
      });
    }),
    doneFeedbackContents: Ember.computed("contents.@each.isFeedbackDone", function () {
      return this.get("contents".filter(function (content) {
        return content.get("isFeedbackDone");
      }));
    }),
    isProgressCompleted: Ember.computed("doneContents", function () {
      return this.get("doneContents.length") === this.get("contents.length");
    }),
    contents: _emberData.default.hasMany("content", {
      async: true
    }),
    run: _emberData.default.belongsTo('run'),
    deadline: _emberData.default.attr(),
    deadlineDate: Ember.computed('deadline', 'run', function () {
      var runStart = _moment.default.unix(this.get('run.start'));

      return this.deadline ? runStart.add(this.deadline, 'd') : undefined;
    }),
    hasDeadlinePassed: Ember.computed('deadlineDate', function () {
      return this.deadlineDate < (0, _moment.default)();
    }),
    startOn: _emberData.default.attr(),
    sectionStartDate: Ember.computed('startOn', 'run', function () {
      var runStart = _moment.default.unix(this.get('run.start'));

      return this.startOn >= 0 ? runStart.add(this.startOn, 'd') : undefined;
    }),
    hasStarted: Ember.computed('sectionStartDate', function () {
      return this.sectionStartDate ? (0, _moment.default)() >= this.sectionStartDate : true;
    }),
    contestId: _emberData.default.attr('number')
  });

  _exports.default = _default;
});