define("codingblocks-online/pods/classroom/track/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gXyeK4Jk",
    "block": "{\"symbols\":[],\"statements\":[[5,\"tracks/tracks-course-row-loading\",[],[[],[]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/classroom/track/loading/template.hbs"
    }
  });

  _exports.default = _default;
});