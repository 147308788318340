define("codingblocks-online/models/notification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr(),
    message: _emberData.default.attr(),
    url: _emberData.default.attr(),
    triggerID: _emberData.default.attr(),
    triggerType: _emberData.default.attr(),
    run: _emberData.default.belongsTo('run'),
    course: _emberData.default.belongsTo('course'),
    user: _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});