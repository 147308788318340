define("codingblocks-online/pods/components/all-courses/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1+QnOSN0",
    "block": "{\"symbols\":[\"card\",\"course\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"t-align-c mt-5 mb-5\"],[8],[0,\"\\n    All Courses\\n  \"],[9],[0,\"\\n  \"],[5,\"card-grid\",[[12,\"class\",\"row justify-content-center\"]],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"courses\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"c-layout-card col-md-6 col-lg-4 pr-md-0\"],[8],[0,\"\\n        \"],[1,[28,\"course-card\",null,[[\"course\",\"index\",\"showPopUp\",\"onChangePopped\",\"organization\"],[[23,2,[]],[23,3,[]],[28,\"eq\",[[23,3,[]],[23,1,[\"poppedIndex\"]]],null],[23,1,[\"changePoppedCard\"]],[24,[\"org\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[2,3]},null],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n  \\n  \"],[7,\"div\",true],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"fetchAllCourses\",\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[5,\"loading-card-classroom\",[[12,\"class\",\"w-100\"]],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"lt\",[[24,[\"limitandoffset\"]],[24,[\"count\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"id\",\"load-more\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/all-courses/template.hbs"
    }
  });

  _exports.default = _default;
});