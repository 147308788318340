define("codingblocks-online/pods/preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CV7yaroB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"timeline\"],[10,\"id\",\"timelineContainer\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"d-flex align-items-end justify-content-lg-end mt-3 mr-3\"],[8],[0,\"\\n    \"],[7,\"a\",true],[11,\"href\",[29,[\"/courses/\",[24,[\"course\",\"slug\"]],\"/#choose-batch\"]]],[10,\"target\",\"_blank\"],[10,\"class\",\"button-solid button-orange\"],[8],[0,\"\\n      Buy Now\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"divider-h mt-2\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row no-gutters timeline__main-area\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-lg-3 col-12 timeline__main-area__content-bar pr-4 order-2\"],[10,\"id\",\"contentScrollContainer\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"position-sticky d-lg-block d-none\"],[8],[0,\"\\n        \"],[7,\"h4\",true],[10,\"class\",\"bold font-mds\"],[10,\"id\",\"start-player-tour\"],[8],[0,\"\\n          Course Content\\n          \"],[7,\"i\",false],[12,\"class\",\"far fa-question-circle icon-hover-fill pointer\"],[12,\"title\",\"Take Tour\"],[3,\"action\",[[23,0,[]],\"startTour\"]],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"divider-h\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"collapsible-content \",[28,\"if\",[[24,[\"contentListCollpased\"]],\"collapsible-content--hidden\"],null]]]],[8],[0,\"\\n        \"],[5,\"player/player-section-list\",[],[[\"@currentSectionId\",\"@sections\",\"@mode\"],[[22,\"currentSectionId\"],[22,\"sections\"],\"non-premium\"]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/preview/template.hbs"
    }
  });

  _exports.default = _default;
});