define("codingblocks-online/pods/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ABSOLUTE_PATH_PREFIX = "absolute_path:";
  /*
    redirectTarget: absolute_path:http://online.codingblocks.com/courses   // set by Nuxt (must be absolute path as these can't be resolved inside ember)
    redirectTarget: /app/classroom/  // can be resolved in ember, no need to reload entire page
  */

  function handleRedirectionTarget(redirectTarget) {
    var _this = this;

    var isAbsolutePath = redirectTarget.startsWith(ABSOLUTE_PATH_PREFIX);

    if (isAbsolutePath) {
      window.location.href = redirectTarget.replace(ABSOLUTE_PATH_PREFIX, "");
    } else {
      Ember.run.later(function () {
        _this.transitionTo(redirectTarget, {
          code: null
        });
      });
    }
  }

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    model: function model() {
      var redirectTarget = window.localStorage.getItem('redirectionPath');

      if (redirectTarget && redirectTarget != '/') {
        handleRedirectionTarget.call(this, redirectTarget);
        window.localStorage.removeItem('redirectionPath');
      } else {
        this.transitionTo('dashboard');
      }
    }
  });

  _exports.default = _default;
});