define("codingblocks-online/pods/components/my-courses-list/expired-courses/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BmCfTxxK",
    "block": "{\"symbols\":[\"run\",\"progressPercent\",\"@runs\"],\"statements\":[[4,\"each\",[[23,3,[]]],null,{\"statements\":[[0,\"  \"],[5,\"my-courses-list/run-row\",[],[[\"@run\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n\\n    \"],[5,\"my-courses-list/expired-courses/content\",[],[[\"@progressPercent\",\"@tagName\",\"@run\"],[[23,2,[]],\"\",[23,1,[]]]]],[0,\"\\n\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/my-courses-list/expired-courses/template.hbs"
    }
  });

  _exports.default = _default;
});