define("codingblocks-online/pods/courses/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uFXe/RTE",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"all-courses\",null,[[\"org\"],[[24,[\"org\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/courses/index/template.hbs"
    }
  });

  _exports.default = _default;
});