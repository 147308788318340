define("codingblocks-online/models/run-attempt", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    premium: _emberData.default.attr(),
    end: _emberData.default.attr('number'),
    revoked: _emberData.default.attr(),
    certificateApproved: _emberData.default.attr(),
    rating: _emberData.default.attr(),
    runTier: _emberData.default.attr(),
    completedContents: _emberData.default.attr('number'),
    approvalRequested: _emberData.default.attr('boolean'),
    doubtSupport: _emberData.default.attr('date'),
    paused: _emberData.default.attr('boolean'),
    pauseTimeLeft: _emberData.default.attr('number'),
    lastPausedAt: _emberData.default.attr('date'),
    run: _emberData.default.belongsTo('run'),
    user: _emberData.default.belongsTo('user'),
    certificates: _emberData.default.hasMany('certificate'),
    notes: _emberData.default.hasMany('note'),
    doubts: _emberData.default.hasMany('doubt'),
    endDate: Ember.computed('end', function () {
      return _moment.default.unix(this.end).toDate();
    }),
    isExpired: Ember.computed('end', function () {
      return this.end < +new Date() / 1000;
    }),
    progressPercent: Ember.computed('completedContents', 'run.totalContents', function () {
      var totalContents = this.get('run.totalContents');
      var completedContents = this.completedContents || 0;
      return totalContents == 0 ? 0 : Math.floor(completedContents * 100 / totalContents);
    }),
    isPausable: Ember.computed('runTier', 'pauseTimeLeft', function () {
      return this.premium && !this.isExpired && (['PREMIUM', 'LIVE'].includes(this.runTier) || this.runTier == null) && this.pauseTimeLeft >= 1 * 24 * 60 * 60 * 1000;
    }),
    completionCertificate: Ember.computed('ceritificates', function () {
      return this.certificates.filter(function (c) {
        return c.type != 'excellence';
      });
    }),
    excellenceCertificate: Ember.computed('certificates', function () {
      return this.certificates.filter(function (c) {
        return c.type == 'excellence';
      });
    })
  });

  _exports.default = _default;
});