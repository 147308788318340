define("codingblocks-online/adapters/web-challenge-submission", ["exports", "codingblocks-online/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var baseUrl = this.buildURL(modelName, id, snapshot);

      if (snapshot.adapterOptions.markTop) {
        return baseUrl + '/mark_top';
      }

      return baseUrl;
    }
  });

  _exports.default = _default;
});