define("codingblocks-online/metrics-adapters/gtag", ["exports", "@babel/runtime/helpers/esm/objectWithoutProperties", "ember-metrics/metrics-adapters/base"], function (_exports, _objectWithoutProperties2, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    toStringExtension: function toStringExtension() {
      return 'gtag';
    },
    init: function init() {
      var _arguments = arguments;
      var config = this.config;
      var id = config.id;
      var newScript = document.createElement("script");
      newScript.type = "text/javascript";
      newScript.setAttribute("async", "true");
      newScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + id);
      document.documentElement.firstChild.appendChild(newScript);

      newScript.onload = function () {
        window.dataLayer = window.dataLayer || [];

        window.gtag = function () {
          return dataLayer.push(_arguments);
        };

        gtag('js', new Date());
        gtag('config', id);
      };
    },
    identify: function identify() {},
    trackEvent: function trackEvent(_ref) {
      var event = _ref.event,
          options = (0, _objectWithoutProperties2.default)(_ref, ["event"]);

      try {
        window.gtag('event', event, options);
      } catch (err) {
        console.error('tried to fire event: ', event, " but gtag wasn't available on time");
      }
    },
    trackPage: function trackPage(_ref2) {// window.gtag('event', name, options)

      var name = _ref2.name,
          options = (0, _objectWithoutProperties2.default)(_ref2, ["name"]);
    },
    alias: function alias() {},
    willDestroy: function willDestroy() {
      document.querySelectorAll('script[src*="googletagmanager"]').forEach(function (el) {
        el.parentElement.removeChild(el);
      });
    }
  });

  _exports.default = _default;
});