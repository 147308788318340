define("codingblocks-online/pods/subscriptions/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8wgfETi3",
    "block": "{\"symbols\":[\"track\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"tracks\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"col-md-6 col-lg-4 mt-5\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"border-card border-none p-0 track-card\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"row no-gutters bg-light-grey p-4 track-card__head\"],[11,\"style\",[29,[\"background-image: url(\",[23,1,[\"background\"]],\"); background-position: right\"]]],[8],[0,\"\\n          \"],[7,\"img\",true],[11,\"src\",[29,[[23,1,[\"logo\"]]]]],[10,\"alt\",\"Track Logo\"],[10,\"class\",\"s-80x80 track-card__head__logo\"],[8],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"flex-col align-items-end justify-content-between w-100\"],[10,\"style\",\"margin-left: 6.5rem;\"],[8],[0,\"\\n            \"],[7,\"img\",true],[10,\"src\",\"https://cb-thumbnails.s3.ap-south-1.amazonaws.com/share.svg\"],[8],[9],[0,\"\\n            \"],[7,\"h4\",true],[10,\"class\",\"bold w-100\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"d-flex justify-content-end bg-background-main-3 align-items-center p-4\"],[8],[0,\"\\n\"],[0,\"          \"],[7,\"div\",true],[8],[0,\"\\n\"],[0,\"            \"],[5,\"link-to\",[[12,\"class\",\"button-solid button-orange ml-2\"]],[[\"@route\",\"@model\"],[\"subscriptions.id\",[23,1,[\"id\"]]]],{\"statements\":[[0,\"\\n              Explore\\n            \"]],\"parameters\":[]}],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/subscriptions/index/template.hbs"
    }
  });

  _exports.default = _default;
});