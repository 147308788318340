define("codingblocks-online/helpers/defaultTo", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaultTo = defaultTo;
  _exports.default = void 0;

  function defaultTo(params) {
    var _params = (0, _slicedToArray2.default)(params, 2),
        val = _params[0],
        defaultVal = _params[1];

    return Ember.isNone(val) ? defaultVal : val;
  }

  var _default = Ember.Helper.helper(defaultTo);

  _exports.default = _default;
});