define("codingblocks-online/pods/components/code-challenge/code-challenge-result/code-challenge-result-success/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k3t1OQIW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"border-card border-none mb-5 bg-grey-darker\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"font-sm bold white\"],[8],[0,\"\\n    Compilation Successfull\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"output\"]]],null,{\"statements\":[[0,\"    \"],[7,\"pre\",true],[10,\"class\",\"mt-3 bg-black-lighter p-4\"],[8],[1,[22,\"output\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/code-challenge/code-challenge-result/code-challenge-result-success/template.hbs"
    }
  });

  _exports.default = _default;
});