define("codingblocks-online/pods/404/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UdeR4ecc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"all-center h-100\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"w-60 t-align-c\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"extra-bold font-superlarge\"],[10,\"style\",\"font-size: 10rem\"],[8],[0,\"404\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"light subheading font-xl\"],[8],[0,\"Page not found. \"],[7,\"a\",true],[10,\"class\",\"orange\"],[10,\"href\",\"/courses\"],[8],[0,\" Click here to explore our courses \"],[9],[0,\" \"],[9],[0,\"\\n\\n      \"],[7,\"img\",true],[10,\"src\",\"https://minio.codingblocks.com/amoeba/404-image-min.png\"],[10,\"alt\",\"404\"],[10,\"class\",\"mt-1\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/404/template.hbs"
    }
  });

  _exports.default = _default;
});