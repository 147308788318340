define("codingblocks-online/pods/components/rating-bar/empty-star/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7WdbFLF6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"24\"],[10,\"height\",\"23\"],[10,\"viewBox\",\"0 0 24 23\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\"],[7,\"path\",true],[10,\"d\",\"M11.5558 0.757809L11.5558 0.757786C11.5656 0.731182 11.5783 0.718439 11.5894 0.710713C11.6026 0.70149 11.6213 0.694346 11.6436 0.693177C11.6659 0.69201 11.6853 0.697153 11.6994 0.70495C11.7112 0.711482 11.7251 0.72283 11.7377 0.748265L15.0646 7.48026L15.1962 7.74642L15.4928 7.75833L22.996 8.05951C23.0244 8.06065 23.0404 8.06882 23.0512 8.07695C23.0641 8.08665 23.0767 8.10228 23.0847 8.1231C23.0927 8.14392 23.0938 8.16394 23.0907 8.17973C23.0881 8.19295 23.0817 8.20976 23.0613 8.2296L17.6869 13.474L17.4745 13.6814L17.5548 13.9672L19.5869 21.1962C19.5946 21.2235 19.5918 21.2413 19.5874 21.2541C19.5822 21.2693 19.5712 21.2861 19.5539 21.3001C19.5365 21.3142 19.5178 21.3214 19.5018 21.3234C19.4885 21.325 19.4705 21.3241 19.4453 21.3109L19.2129 21.7535L19.4453 21.3109L12.7968 17.8201L12.5339 17.6821L12.2869 17.8469L6.03972 22.0134C6.01611 22.0292 5.99832 22.032 5.98483 22.0318C5.96873 22.0315 5.94937 22.0262 5.93066 22.0141C5.91195 22.0019 5.89929 21.9864 5.89248 21.9718C5.88679 21.9596 5.88214 21.9422 5.88693 21.9142L5.88694 21.9142L7.15225 14.5124L7.20228 14.2197L6.96929 14.0357L1.07618 9.38176L1.07614 9.38173C1.05387 9.36415 1.04568 9.34812 1.04175 9.33524C1.03705 9.31986 1.03606 9.29984 1.04183 9.2783C1.0476 9.25676 1.05848 9.23991 1.07025 9.22893C1.08011 9.21972 1.09523 9.20993 1.12332 9.20583L8.55393 8.12195L8.84771 8.0791L8.95071 7.80065L11.5558 0.757809Z\"],[10,\"stroke\",\"#6F6F6F\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/rating-bar/empty-star/template.hbs"
    }
  });

  _exports.default = _default;
});