define("codingblocks-online/models/testcase", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    input: _emberData.default.attr(),
    expectedOutput: _emberData.default.attr(),
    "code-challenge": _emberData.default.belongsTo('code-challenge')
  });

  _exports.default = _default;
});