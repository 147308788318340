define("codingblocks-online/pods/components/blockers/upgrade/loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+ieKS92A",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"d-flex justify-content-center my-5\"],[8],[0,\"\\n\"],[7,\"svg\",true],[10,\"version\",\"1.1\"],[10,\"id\",\"L9\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[10,\"x\",\"0px\"],[10,\"y\",\"0px\"],[10,\"viewBox\",\"0 0 100 100\"],[10,\"enable-background\",\"new 0 0 0 0\"],[10,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[10,\"style\",\"width: 100px;height:100px\"],[8],[0,\"\\n      \"],[7,\"path\",true],[10,\"fill\",\"#e3e3e3\"],[10,\"d\",\"M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50\"],[8],[0,\"\\n        \"],[7,\"animateTransform\",true],[10,\"attributeName\",\"transform\"],[10,\"attributeType\",\"XML\"],[10,\"dur\",\"0.8s\"],[10,\"from\",\"0 50 50\"],[10,\"to\",\"360 50 50\"],[10,\"repeatCount\",\"indefinite\"],[10,\"type\",\"rotate\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/blockers/upgrade/loader/template.hbs"
    }
  });

  _exports.default = _default;
});