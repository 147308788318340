define("codingblocks-online/pods/components/csv/csv-submissions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v9AqHz7y",
    "block": "{\"symbols\":[\"submissions\",\"submission\"],\"statements\":[[5,\"w-async\",[],[[\"@task\",\"@autoFire\"],[[22,\"fetchSubmissionsTask\"],\"true\"]],{\"statements\":[[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"list-divided border-none\"],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[10,\"class\",\"list-item d-flex justify-content-between\"],[8],[0,\"\\n        \"],[7,\"h4\",true],[10,\"class\",\"extra-bold\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[\"isSuccess\"]]],null,{\"statements\":[[0,\"            \"],[7,\"span\",true],[10,\"class\",\"green\"],[8],[0,\" \\n              \"],[1,[23,2,[\"score\"]],false],[0,\"% Accurate\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,2,[\"isErrored\"]]],null,{\"statements\":[[0,\"            \"],[7,\"span\",true],[10,\"class\",\"red\"],[8],[0,\" Errored \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"span\",true],[10,\"class\",\"yellow\"],[8],[0,\" Calculating \"],[9],[0,\"\\n          \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n        \"],[7,\"span\",true],[8],[0,\"\\n          \"],[7,\"a\",true],[11,\"href\",[29,[[23,2,[\"outputUrl\"]]]]],[10,\"target\",\"_blank\"],[10,\"class\",\"font-sm bold gradient-text-orange mr-4\"],[8],[0,\"\\n            Download CSV\\n          \"],[9],[0,\"\\n          Submitted \"],[1,[28,\"moment-from\",[[23,2,[\"createdAt\"]]],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/csv/csv-submissions/template.hbs"
    }
  });

  _exports.default = _default;
});