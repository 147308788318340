define("codingblocks-online/models/csv", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    description: _emberData.default.attr(),
    datasetUrl: _emberData.default.attr(),
    testcasesUrl: _emberData.default.attr(),
    csvSubmissions: _emberData.default.hasMany('csv-submission')
  });

  _exports.default = _default;
});