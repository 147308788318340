define("codingblocks-online/pods/components/code-challenge/code-challenge-result/code-challenge-result-error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z7R+h/Rv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"border-card border-none mb-5 bg-grey-darker\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"font-sm bold white\"],[8],[0,\"\\n    Error !\\n  \"],[9],[0,\"\\n  \"],[7,\"pre\",true],[10,\"class\",\"mt-3 bg-black-lighter p-4\"],[8],[1,[22,\"output\"],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/code-challenge/code-challenge-result/code-challenge-result-error/template.hbs"
    }
  });

  _exports.default = _default;
});