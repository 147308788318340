define("codingblocks-online/pods/components/search-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VF9Zdnip",
    "block": "{\"symbols\":[\"course\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"input-search nav-search\"],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"type\",\"placeholder\",\"value\",\"key-press\",\"focusIn\",\"focusOut\"],[\"text\",\"Search here\",[24,[\"qs\"]],[28,\"perform\",[[24,[\"searchTask\"]]],null],[28,\"action\",[[23,0,[]],\"showResult\"],null],[28,\"action\",[[23,0,[]],\"hideResult\"],null]]]],false],[0,\"\\n  \"],[7,\"img\",true],[10,\"src\",\"/images/searchicon.png\"],[10,\"alt\",\"search\"],[8],[9],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"hideResultsBox\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"search-box\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"searchTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"        \"],[1,[22,\"loader-component\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"results\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",false],[12,\"class\",\"row search-content pointer align-items-center\"],[3,\"action\",[[23,0,[]],[24,[\"transitonToResult\"]],[23,1,[]]]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"img-container col-md-3\"],[8],[0,\"\\n          \"],[7,\"img\",true],[11,\"src\",[29,[[23,1,[\"logo\"]]]]],[10,\"alt\",\"online-logo\"],[10,\"class\",\"nav-logo pointer\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"heading-container col-md-9\"],[8],[0,\"\\n          \"],[7,\"div\",true],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"red font-sm\"],[8],[1,[23,1,[\"subtitle\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/search-box/template.hbs"
    }
  });

  _exports.default = _default;
});