define("codingblocks-online/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    username: _emberData.default.attr(),
    firstname: _emberData.default.attr(),
    lastname: _emberData.default.attr(),
    fullName: Ember.computed('firstname', 'lastname', function () {
      return this.firstname + ' ' + this.lastname;
    }),
    email: _emberData.default.attr(),
    college: _emberData.default.attr(),
    organization: _emberData.default.attr(),
    hackJwt: _emberData.default.attr(),
    lastReadNotification: _emberData.default.attr(),
    photo: _emberData.default.attr(),
    oneauthId: _emberData.default.attr(),
    roleId: _emberData.default.attr(),
    verifiedemail: _emberData.default.attr(),
    verifiedmobile: _emberData.default.attr(),
    orgLogo: Ember.computed('organization', function () {
      switch (this.organization) {
        case 'chitkara':
          return 'https://www.chitkara.edu.in/wp-content/themes/chitkara/images/CU_logo.png';

        case 'saitm':
          return 'https://minio.codingblocks.com/public/saitm.png';

        case 'bml':
          return 'https://minio.codingblocks.com/public/bml-logo.jpeg';

        case 'lnmiit':
          return 'https://minio.codingblocks.com/public/lnmit-logo.png';

        case 'manav-rachna':
          return 'https://minio.codingblocks.com/public/manav-rachna.jpeg';

        case 'mnit-jaipur':
          return 'https://www.mnit.ac.in/Images/Mnit_logo.png';

        default:
          return 'https://minio.codingblocks.com/amoeba/OnlineLogo2020.svg';
      }
    }) //contents: DS.hasMany('content'),
    //courseRuns: DS.hasMany('run'),
    //runAttempt: DS.belongsTo('run-attempt')

  });

  _exports.default = _default;
});