define("codingblocks-online/pods/mnit-jaipur/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1w9rkAqn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"chitkara-banner p-5\"],[10,\"style\",\"border-radius: 30px;\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"information row justify-content-between mt-md-5 mt-lg-0\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"title col-lg-6 col-md-8 mt-lg-5\"],[8],[0,\"Courses specially curated for \"],[7,\"br\",true],[8],[9],[0,\"\\n        MNIT Jaipur\"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-2\"],[8],[7,\"img\",true],[10,\"class\",\"logo d-none d-md-block\"],[10,\"src\",\"https://www.mnit.ac.in/Images/Mnit_logo.png\"],[10,\"alt\",\"chitkara-logo\"],[8],[9],[9],[0,\"\\n    \"],[9],[0,\"\\n    \\n  \"],[7,\"div\",true],[10,\"class\",\"row justify-content-between\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-5 align-self-end mb-5 d-none d-md-block\"],[8],[0,\"\\n      \\n      \"],[7,\"button\",false],[12,\"class\",\"button-solid white-button font-lg\"],[3,\"action\",[[23,0,[]],\"goToRecommendedCourses\"]],[8],[0,\"Explore Courses\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"banner-image col-md-7\"],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"src\",\"/app/images/chitkara-university-banner.png\"],[10,\"alt\",\"chitkara-banner-image\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"d-md-none row justify-content-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[7,\"button\",false],[12,\"class\",\"button-solid white-button mt-5\"],[3,\"action\",[[23,0,[]],\"goToRecommendedCourses\"]],[8],[0,\"Explore Courses\"],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"container mt-5\"],[10,\"id\",\"recommended-courses\"],[8],[0,\"\\n  \"],[1,[28,\"recommended-courses\",null,[[\"organization\"],[\"chitkara\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[1,[22,\"offerings-static\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/mnit-jaipur/index/template.hbs"
    }
  });

  _exports.default = _default;
});