define("codingblocks-online/models/carousel-card", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr("string"),
    subtitle: _emberData.default.attr("string"),
    img: _emberData.default.attr("string"),
    theme: _emberData.default.attr('string', {
      defaultValue: 'bg-gradient-purple'
    }),
    buttonLink: _emberData.default.attr("string"),
    buttonText: _emberData.default.attr("string"),
    order: _emberData.default.attr("number")
  });

  _exports.default = _default;
});