define("codingblocks-online/pods/spin/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    actions: {
      willTransition: function willTransition() {
        window.setTimeout(function () {
          return jivo_init();
        }, 5000);
      },
      didTransition: function didTransition() {
        jivo_init();
      }
    }
  });

  _exports.default = _default;
});