define("codingblocks-online/utils/seo", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "codingblocks-online/config/environment", "moment"], function (_exports, _toConsumableArray2, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSeoSchemaForAllCourses = _exports.getSeoSchemaForCourse = void 0;

  var getSeoSchemaForCourse = function getSeoSchemaForCourse(course, ratings) {
    var data = {
      "@context": "http://schema.org/",
      "@type": "Course",
      "name": course.title,
      "description": course.subtitle,
      "image": [course.logo],
      "provider": {
        "@type": "Organization",
        "name": "Coding Blocks",
        "sameAs": "https://online.codingblocks.com/"
      },
      "review": {
        "@type": "Review",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": ratings.length > 0 ? ratings[0].value : 5,
          "bestRating": "5"
        },
        "author": {
          "@type": "Person",
          "name": ratings.length > 0 ? ratings[0].user.firstname + ' ' + ratings[0].user.lastname : 'Abhishek Gupta'
        }
      },
      "url": "".concat(_environment.default.publicUrl, "/courses/").concat(course.slug),
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": course.rating,
        "reviewCount": parseInt(course.id) * 9 || 40
      },
      "offers": {
        "@type": "Offer",
        "priceCurrency": "INR",
        "priceValidUntil": course.get("activeRuns.length") > 0 ? _moment.default.unix(course.get("activeRuns.firstObject.enrollmentEnd")).format('YYYY-MM-DD') : _moment.default.unix(Date.now() / 1000).format('YYYY-MM-DD'),
        "price": Math.min.apply(Math, (0, _toConsumableArray2.default)(course.runs.map(function (r) {
          return r.price;
        }))),
        "availability": "http://schema.org/InStock",
        "seller": {
          "@type": "Organization",
          "name": "Coding Blocks"
        },
        "url": "".concat(_environment.default.publicUrl, "/courses/").concat(course.slug)
      }
    };
    return JSON.stringify(data);
  };

  _exports.getSeoSchemaForCourse = getSeoSchemaForCourse;

  var getSeoSchemaForAllCourses = function getSeoSchemaForAllCourses(courses, callingPage) {
    var items = courses.map(function (course, index) {
      return {
        "@type": "ListItem",
        "position": index + 1,
        "item": {
          "@context": "http://schema.org",
          "@type": "Course",
          "name": course.title,
          "description": course.subtitle,
          "provider": {
            "@type": "Organization",
            "name": "Coding Blocks",
            "sameAs": "https://online.codingblocks.com/"
          },
          "url": callingPage ? "".concat(_environment.default.publicUrl, "/").concat(callingPage, "#").concat(course.slug) : "".concat(_environment.default.publicUrl, "/#").concat(course.slug)
        }
      };
    });
    var data = {
      "@context": "http://schema.org",
      "@type": "ItemList",
      "itemListElement": items
    };
    return JSON.stringify(data);
  };

  _exports.getSeoSchemaForAllCourses = getSeoSchemaForAllCourses;
});