define("codingblocks-online/pods/components/blockers/fillup-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sri+frin",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fas fa-lock fa-4x grey\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"white\"],[8],[0,\"\\n    Please fill up required details(firstname, mobile, email) to get started\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row justify-content-center my-2\"],[8],[0,\"\\n  \"],[7,\"a\",true],[10,\"class\",\"button-solid button-orange mr-3\"],[10,\"href\",\"https://account.codingblocks.com/users/me\"],[8],[0,\" Edit Profile \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/blockers/fillup-name/template.hbs"
    }
  });

  _exports.default = _default;
});