define("codingblocks-online/pods/components/jobs-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GQqmDf/o",
    "block": "{\"symbols\":[\"job\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"border-card border-none bg-background-main-3 py-lg-5 py-4 px-0\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"bold px-lg-5 px-4\"],[8],[0,\"Jobs\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"divider-h content-divider mt-4 px-lg-5 px-4\"],[8],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"jobs\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",false],[12,\"class\",\"pointer\"],[3,\"action\",[[23,0,[]],\"redirectToJob\",[23,1,[\"id\"]]]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row no-gutters align-items-center hover-grey py-4 px-lg-5 px-4\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"d-flex align-items-center\"],[8],[0,\"\\n          \"],[7,\"img\",true],[11,\"src\",[29,[[23,1,[\"company\",\"logo\"]]]]],[10,\"class\",\"s-60x60 mr-3\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n          \"],[7,\"div\",true],[8],[0,\"\\n            \"],[7,\"h5\",true],[10,\"class\",\"bold\"],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n            \"],[7,\"span\",true],[8],[1,[23,1,[\"company\",\"name\"]],false],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/jobs-card/template.hbs"
    }
  });

  _exports.default = _default;
});