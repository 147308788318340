define("codingblocks-online/helpers/percentage", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.percentage = percentage;
  _exports.default = void 0;

  function percentage(params) {
    var _params = (0, _slicedToArray2.default)(params, 2),
        x = _params[0],
        total = _params[1];

    var result = Math.ceil(x / total * 100);
    return result || 0;
  }

  var _default = Ember.Helper.helper(percentage);

  _exports.default = _default;
});