define("codingblocks-online/services/youtube-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isYoutubeApiReady: false,
    // is api is ready or not
    player: null,
    // youtube api Player instance
    active: false,
    initialize: function initialize(playerElementId) {
      var _this = this;

      this.set('active', true);

      if (!this.isYoutubeApiReady) {
        var tag = document.createElement('script');
        tag.id = 'iframe-demo';
        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        var playerPromise = new Promise(function (resolve) {
          window.onYouTubeIframeAPIReady = function () {
            _this._setPlayer(playerElementId).then(resolve);
          };
        });
        return playerPromise;
      } else {
        return this._setPlayer(playerElementId);
      }
    },
    _setPlayer: function _setPlayer(playerId) {
      var _this2 = this;

      var playerPromise = new Promise(function (resolve, reject) {
        var player = new YT.Player(playerId, {
          events: {
            onReady: function onReady() {
              _this2.set("isYoutubeApiReady", true);

              resolve(player);
            }
          }
        });
      });
      playerPromise.then(function (player) {
        return _this2.set("player", player);
      });
      return playerPromise;
    },
    destroy: function destroy() {
      if (this.player) {
        this.player.destroy();
      }

      this.set('active', false);
    },
    getCurrentTime: function getCurrentTime() {
      if (this.player) return this.player.getCurrentTime();else throw new Error('player: is not set!, Maybe you forgot to initialize first!');
    },
    seek: function seek(time) {
      try {
        this.player.seekTo(time, true);
      } catch (e) {
        console.log("Error: Cannot seek youtube player", e);
      }
    }
  });

  _exports.default = _default;
});