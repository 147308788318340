define("codingblocks-online/pods/components/player/player-notes-tab/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+a9DkI+Y",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row no-gutters bg-grey-darker p-3 justify-content-between mt-5 align-items-center br-10\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex-1\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row no-gutters align-items-center\"],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"src\",[29,[[24,[\"currentUser\",\"user\",\"photo\"]]]]],[10,\"alt\",\"user-photo\"],[10,\"class\",\"round s-50x50\"],[8],[9],[0,\"\\n      \"],[5,\"textarea\",[[12,\"placeholder\",\"Add a Note\"],[12,\"class\",\"underline-input flex-1 mx-4\"]],[[\"@value\"],[[22,\"newNoteText\"]]],{\"statements\":[[0,\"  \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"button-solid button-orange\"],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"addNoteTask\"]]],null]]],[8],[0,\"\\n    Save\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"player/player-notes-list\",[],[[\"@notes\"],[[22,\"sortedNotes\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/player/player-notes-tab/template.hbs"
    }
  });

  _exports.default = _default;
});