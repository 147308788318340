define("codingblocks-online/models/content", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    contentable: _emberData.default.attr(),
    title: _emberData.default.attr(),
    sectionContent: _emberData.default.attr(),
    maxScore: _emberData.default.attr('number'),
    course: _emberData.default.belongsTo('course'),
    qna: _emberData.default.belongsTo('qna'),
    lecture: _emberData.default.belongsTo('lecture'),
    "code-challenge": _emberData.default.belongsTo('code-challenge'),
    "web-challenge": _emberData.default.belongsTo('web-challenge'),
    document: _emberData.default.belongsTo('document'),
    csv: _emberData.default.belongsTo('csv'),
    //attachment: DS.belongsTo('attachment'),
    video: _emberData.default.belongsTo('video'),
    webinar: _emberData.default.belongsTo('webinar'),
    "course-recommend": _emberData.default.belongsTo('course-recommend'),
    duration: _emberData.default.attr(),
    payload: Ember.computed('contentable', 'qna', 'lecture', 'code-challenge', 'web-challenge', 'document', 'video', 'csv', 'webinar', 'course-recommend', function () {
      return this.get(this.contentable);
    }),
    isDone: Ember.computed.bool('progress.isDone'),
    isActive: Ember.computed.bool('progress.isActive'),
    isFeedbackDone: Ember.computed('progress.isFeedbackDone', function () {
      return !!this.get('progress.isFeedbackDone');
    }),
    iconClass: Ember.computed('contentable', function () {
      switch (this.contentable) {
        case 'document':
          return 'notes-row';
          break;

        case 'code-challenge':
          return 'code-row';
          break;

        case 'web-challenge':
          return 'code-row';
          break;

        case 'qna':
          return 'quiz-row';
          break;

        case 'lecture':
        default:
          return 'video-row';
          break;
      }
    }),
    progress: _emberData.default.belongsTo('progress'),
    bookmark: _emberData.default.belongsTo('bookmark')
  });

  _exports.default = _default;
});