define("codingblocks-online/models/user-course-wishlist", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    course: _emberData.default.belongsTo('course'),
    user: _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});