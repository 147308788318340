define("codingblocks-online/pods/attempt/content/quiz/attempt/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      q: {
        replace: true
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        quizAttempt: this.modelFor('attempt.content.quiz.attempt'),
        quiz: this.modelFor('attempt.content.quiz').quiz
      });
    },
    afterModel: function afterModel(model, transition) {
      if (model.quizAttempt.get('status') === 'FINAL') {
        this.transitionTo('attempt.content.quiz');
      }
    },
    setupController: function setupController(controller, model) {
      controller.set("quizAttempt", model.quizAttempt);
      controller.set("quiz", model.quiz);
      controller.set("questions", model.quiz.get('questions'));

      this._super.apply(this, arguments);
    },
    resetController: function resetController(controller, isExiting, transition) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.set('currentQuestion.markedChoices', []);
      }
    }
  });

  _exports.default = _default;
});