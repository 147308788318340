define("codingblocks-online/helpers/multiply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.multiply = multiply;
  _exports.default = void 0;

  function multiply(params
  /*, hash*/
  ) {
    var res = params[0] * params[1];
    return isNaN(+res) ? 0 : +res;
  }

  var _default = Ember.Helper.helper(multiply);

  _exports.default = _default;
});