define("codingblocks-online/pods/components/file-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      uploaded: function uploaded(e) {
        this.onComplete(e.link);
        this.set('triggerUpload', false);
      },
      reset: function reset() {
        this.onComplete(null);
        this.set('triggerUpload', false);
      },
      uploadFailed: function uploadFailed() {
        alert("Can't Upload file.");
        this.set('triggerUpload', false);
      }
    }
  });

  _exports.default = _default;
});