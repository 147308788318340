define("codingblocks-online/pods/subscribe/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZdxTPGXs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"my-4\"],[8],[0,\"\\n  Hi, you've been invited to participate in early our push notification channel. Click on the button below and subscribe to push \\n  notifications from codingblocks.\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"onesignal-customlink-container w-100\"],[10,\"style\",\"height:200px\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/subscribe/template.hbs"
    }
  });

  _exports.default = _default;
});