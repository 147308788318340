define("codingblocks-online/pods/attempt/content/csv/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ojOtGqNF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"p-0 mb-5 border-card white border-none bg-grey-darker\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"p-5\"],[8],[0,\"\\n    \"],[5,\"w-tab-nav\",[],[[\"@inactiveClass\",\"@tabs\",\"@activeTab\",\"@onTabChange\"],[\"white\",[22,\"tabs\"],[22,\"activeTab\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"activeTab\"]]],null]],null]]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"py-4\"],[8],[0,\"\\n        \"],[1,[28,\"component\",[[24,[\"activeTab\",\"component\"]]],[[\"csv\",\"content\"],[[24,[\"csv\"]],[24,[\"content\"]]]]],false],[0,\"  \\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"p-4\"],[10,\"style\",\"background-color: #23252C;\"],[8],[0,\"\\n    \"],[5,\"file-input\",[],[[\"@link\",\"@onComplete\"],[[22,\"url\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"url\"]]],null]],null]]],{\"statements\":[[0,\"  \\n\"],[4,\"if\",[[24,[\"url\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\",false],[12,\"class\",\"float-right button-solid button-orange\"],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"submissionTask\"]]],null]]],[8],[0,\"\\n          \"],[1,[28,\"if\",[[24,[\"submissionTask\",\"isRunning\"]],\"Submitting\",\"Submit\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"currentSubmission\"]]],null,{\"statements\":[[0,\"  \"],[5,\"csv/csv-submission-success\",[[12,\"class\",\"mt-5\"]],[[\"@submission\"],[[22,\"currentSubmission\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/attempt/content/csv/template.hbs"
    }
  });

  _exports.default = _default;
});