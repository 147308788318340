define("codingblocks-online/pods/components/tracks/tracks-course-row-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AXCAsEfQ",
    "block": "{\"symbols\":[\"placeholder\"],\"statements\":[[4,\"content-placeholders\",null,[[\"rounded\"],[true]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"px-5 pt-5 position-relative\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"track-indicator gradient-text-orange complete\"],[8],[0,\"•\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"normal\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row align-items-center my-5\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-6 col-12 mb-md-0 mb-3\"],[8],[0,\"\\n            \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"heading\"]],\"expected `placeholder.heading` to be a contextual component but found a string. Did you mean `(component placeholder.heading)`? ('codingblocks-online/pods/components/tracks/tracks-course-row-loading/template.hbs' @ L7:C14) \"],null]],[[\"img\"],[true]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-3 col-6\"],[8],[0,\"\\n        \\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-3 col-6\"],[8],[0,\"\\n         \"],[7,\"button\",true],[10,\"class\",\"button-solid button-placeholder\"],[8],[0,\"\\n            \"],[1,[23,1,[\"img\"]],false],[0,\"\\n          \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \\n    \"],[1,[23,1,[\"text\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"divider-h content-divider mt-5 pl-5\"],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/tracks/tracks-course-row-loading/template.hbs"
    }
  });

  _exports.default = _default;
});