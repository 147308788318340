define("codingblocks-online/utils/content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.slugify = _exports.defaultProgressValueForContent = void 0;

  var defaultProgressValueForContent = function defaultProgressValueForContent(content) {
    switch (content.contentable) {
      case 'code-challenge':
      case 'web-challenge':
        return 'ACTIVE';

      case 'lecture':
        return 'ACTIVE';

      default:
        return 'DONE';
    }
  };

  _exports.defaultProgressValueForContent = defaultProgressValueForContent;

  var slugify = function slugify(title) {
    return title.trim().replace(/ ?- ?/g, "-").replace(/ /g, "-");
  };

  _exports.slugify = slugify;
});