define("codingblocks-online/pods/components/rating-bar/filled-star/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "20u0AL2t",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"24\"],[10,\"height\",\"23\"],[10,\"viewBox\",\"0 0 24 23\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\"],[7,\"path\",true],[10,\"d\",\"M10.8963 0.584351L8.29121 7.62719L0.860599 8.71107C0.340299 8.78696 0.163087 9.44833 0.575745 9.77416L6.46885 14.4281L5.20353 21.8299C5.1149 22.3482 5.68916 22.7211 6.12658 22.4294L12.3738 18.2628L19.0224 21.7535C19.4879 21.998 20.02 21.567 19.8777 21.0609L17.8456 13.8319L23.22 8.58745C23.5963 8.22022 23.3509 7.58105 22.8255 7.55991L15.3223 7.25873L11.9954 0.526719C11.7624 0.0553803 11.0786 0.0912335 10.8963 0.584351Z\"],[10,\"fill\",\"url(#paint0_linear)\"],[8],[9],[0,\"\\n\"],[7,\"defs\",true],[8],[0,\"\\n\"],[7,\"linearGradient\",true],[10,\"id\",\"paint0_linear\"],[10,\"x1\",\"18.6443\"],[10,\"y1\",\"25.9953\"],[10,\"x2\",\"24.619\"],[10,\"y2\",\"3.69749\"],[10,\"gradientUnits\",\"userSpaceOnUse\"],[8],[0,\"\\n\"],[7,\"stop\",true],[10,\"stop-color\",\"#FB8133\"],[8],[9],[0,\"\\n\"],[7,\"stop\",true],[10,\"offset\",\"1\"],[10,\"stop-color\",\"#FFBA08\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/rating-bar/filled-star/template.hbs"
    }
  });

  _exports.default = _default;
});