define("codingblocks-online/pods/components/run-request-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "btAV4g90",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"request\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"isPending\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"class\",\"button-solid\"],[8],[0,\"Request Pending\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isDeclined\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"class\",\"button-solid\"],[8],[0,\"Request Declined\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isAccepted\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"class\",\"button-solid\"],[8],[0,\"\\n      \"],[4,\"link-to\",null,[[\"tagName\",\"activeClass\",\"route\",\"models\"],[\"button\",\"\",\"classroom.timeline\",[28,\"array\",[[24,[\"run\",\"course\",\"id\"]],[24,[\"run\",\"id\"]]],null]]],{\"statements\":[[0,\" \"],[7,\"i\",true],[10,\"class\",\"fas fa-play\"],[8],[9],[0,\" Resume\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\" \"],[0,\"\\n  \\n    \"],[7,\"button\",false],[12,\"class\",\"button-solid\"],[3,\"action\",[[23,0,[]],\"addRequest\"]],[8],[0,\"\\n      Request\\n    \"],[9],[0,\"\\n  \\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/run-request-button/template.hbs"
    }
  });

  _exports.default = _default;
});