define("codingblocks-online/pods/attempt/content/quiz/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        quiz: this.modelFor('attempt.content.quiz').quiz,
        qna: this.modelFor('attempt.content.quiz').payload,
        sectionId: this.paramsFor('attempt').sectionId,
        newQuizAttempt: this.store.createRecord('quiz_attempt', {
          status: 'draft',
          runAttempt: this.store.peekRecord('run_attempt', this.paramsFor('attempt').runAttemptId),
          qna: this.store.peekRecord('content', this.paramsFor('attempt.content').contentId).get('payload')
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.set("quiz", model.quiz);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});