define("codingblocks-online/pods/components/rating-stars-static/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EOZs+u+D",
    "block": "{\"symbols\":[\"index\"],\"statements\":[[2,\"showValue: {type: bool, description: to show total number of reviews}\"],[0,\"\\n\"],[4,\"each\",[[28,\"range\",[1,6],null]],null,{\"statements\":[[0,\"   \"],[7,\"img\",true],[11,\"src\",[29,[\"https://cb-thumbnails.s3.ap-south-1.amazonaws.com/star_\",[28,\"if\",[[28,\"lte\",[[23,1,[]],[24,[\"rating\"]]],null],\"filled\",\"unfilled\"],null],\".svg\"]]],[10,\"alt\",\"\"],[10,\"class\",\"mr-1\"],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showValue\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"edit-rating\"],[8],[1,[22,\"rating\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/rating-stars-static/template.hbs"
    }
  });

  _exports.default = _default;
});