define("codingblocks-online/pods/components/web-challenge/web-challenge-explanation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "shMlwuPa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"class\",\"bold\"],[8],[0,\"\\n  \"],[1,[24,[\"webChallenge\",\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"\\n  \"],[1,[28,\"markdown-to-html\",[[24,[\"webChallenge\",\"description\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/web-challenge/web-challenge-explanation/template.hbs"
    }
  });

  _exports.default = _default;
});