define("codingblocks-online/services/hb-api", ["exports", "codingblocks-online/config/environment", "ember-ajax/services/ajax"], function (_exports, _environment, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    host: _environment.default.hackApiHost,
    contentType: 'application/json; charset=utf-8',
    namespace: '/api/v2',
    headers: Ember.computed('currentUser.user.hackJwt', function () {
      return {
        Authorization: "JWT ".concat(this.get('currentUser.user.hackJwt')),
        'user-id': JSON.parse(window.atob(this.get('currentUser.user.hackJwt').split('.')[1].replace(/-/g, '+').replace(/_/g, '/'))).id,
        'client': 'online-cb'
      };
    })
  });

  _exports.default = _default;
});