define("codingblocks-online/models/progress", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    status: _emberData.default.attr(),
    feedbackStatus: _emberData.default.attr(),
    score: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    isDone: Ember.computed('status', function () {
      switch (this.get('content.contentable')) {
        case 'code-challenge':
        default:
          return this.status === 'DONE';
      }
    }),
    isActive: Ember.computed('status', function () {
      return this.status === 'ACTIVE';
    }),
    isFeedbackDone: Ember.computed('feedbackStatus', function () {
      return !(this.feedbackStatus == null);
    }),
    feedback: _emberData.default.attr(),
    runAttempt: _emberData.default.belongsTo('runAttempt'),
    content: _emberData.default.belongsTo('content')
  });

  _exports.default = _default;
});