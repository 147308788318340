define("codingblocks-online/pods/components/review-pad/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    circleClassNames: Ember.computed('submission', function () {
      var submission = this.submission;
      return this.questions.map(function (question) {
        if (!Array.isArray(submission)) {
          return '';
        }

        var questionSubmission = submission.find(function (el) {
          return el.id == question.get('id');
        });

        if (!questionSubmission || !questionSubmission['marked-choices'].length) {
          return '';
        } else {
          return 'bg-green';
        }
      });
    })
  });

  _exports.default = _default;
});