define("codingblocks-online/pods/components/blockers/paused-run-attempt/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QOV15l8z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"p-5\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[5,\"fa-icon\",[],[[\"@icon\",\"@size\"],[\"lock\",\"3\"]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mx-auto\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"my-3 t-align-c\"],[8],[0,\"Course Access Paused\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"mt-3  t-align-c\"],[8],[0,\"You paused this course on \"],[1,[28,\"moment-format\",[[24,[\"runAttempt\",\"lastPausedAt\"]],\"DD MMM YY HH:mm\"],null],false],[0,\". You must resume it before continuing\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"mt-3\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"button-orange button-solid\"],[3,\"action\",[[23,0,[]],\"unpauseRunAttempt\"]],[8],[0,\"UnPause\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/blockers/paused-run-attempt/template.hbs"
    }
  });

  _exports.default = _default;
});