define("codingblocks-online/data/buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    cancel: {
      classes: 'button-solid',
      secondary: true,
      text: 'Exit',
      type: 'cancel'
    },
    next: {
      classes: 'button-solid button-orange',
      text: 'Next',
      type: 'next'
    },
    back: {
      classes: 'button-solid',
      secondary: true,
      text: 'Back',
      type: 'back'
    }
  };
  _exports.default = _default;
});