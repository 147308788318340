define("codingblocks-online/utils/run", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.iconForTier = void 0;

  var iconForTier = function iconForTier(tier) {
    switch (tier) {
      case 'LITE':
        return 'https://cb-thumbnails.s3.ap-south-1.amazonaws.com/lite.png';

      case 'PREMIUM':
        return 'https://cb-thumbnails.s3.ap-south-1.amazonaws.com/premium.png';

      case 'LIVE':
        return 'https://cb-thumbnails.s3.ap-south-1.amazonaws.com/live.png';

      case 'CLASSROOM':
        return 'https://cb-thumbnails.s3.ap-south-1.amazonaws.com/classroom.png';

      default:
        return 'https://cb-thumbnails.s3.ap-south-1.amazonaws.com/lite.png';
    }
  };

  _exports.iconForTier = iconForTier;
});