define("codingblocks-online/services/api", ["exports", "codingblocks-online/config/environment", "ember-ajax/services/ajax"], function (_exports, _environment, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    host: _environment.default.apiHost,
    contentType: "application/json; charset=utf-8",
    namespace: "/api/v2",
    headers: Ember.computed("session.data.token", function () {
      var headers = {};
      var jwt = this.get("session.data.token");

      if (jwt) {
        headers["Authorization"] = "JWT ".concat(jwt);
      }

      return headers;
    }),
    options: function options() {
      var res = this._super.apply(this, arguments);

      res.xhrFields = {
        withCredentials: true
      };
      return res;
    }
  });

  _exports.default = _default;
});