define("codingblocks-online/pods/certificate/route", ["exports", "ember-ajax/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    api: Ember.inject.service(),
    model: function model(_ref) {
      var licenseKey = _ref.licenseKey;
      return this.api.request('certificates/' + licenseKey);
    },
    actions: {
      error: function error(_error, transition) {
        if ((0, _errors.isBadRequestError)(_error) || (0, _errors.isNotFoundError)(_error)) {
          // https://github.com/emberjs/ember.js/issues/12624
          this.intermediateTransitionTo('404', 'DONOT_REMOVE_ME');
        } else throw _error;
      }
    }
  });

  _exports.default = _default;
});