define("codingblocks-online/pods/components/code-challenge/code-challenge-solution/code-challenge-solution-editorial/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r7yczLUI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"red row justify-content-between no-gutters\"],[8],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    Editorial\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grey\"],[8],[0,\"\\n    \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],\"onClose\"]],[8],[0,\"X\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[8],[0,\"\\n  \"],[1,[28,\"markdown-to-html\",[[24,[\"editorial\",\"content\"]]],[[\"extensions\"],[\"katex\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/code-challenge/code-challenge-solution/code-challenge-solution-editorial/template.hbs"
    }
  });

  _exports.default = _default;
});