define("codingblocks-online/helpers/base64", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.base64 = base64;
  _exports.default = void 0;

  function base64(param) {
    if (param[0] === 'decode') return window.atob(param[1]);else if (param[0] === 'encode') return window.btoa(param[1]);else return 'INVALID 1st argument, pass either "decode" or "encode"';
  }

  var _default = Ember.Helper.helper(base64);

  _exports.default = _default;
});