define("codingblocks-online/pods/classroom/timeline/library/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eA36sGCi",
    "block": "{\"symbols\":[\"result\"],\"statements\":[[0,\"\\n\"],[5,\"w-tab-nav\",[],[[\"@tabs\",\"@activeTab\",\"@onTabChange\"],[[22,\"tabs\"],[22,\"activeTab\"],[28,\"action\",[[23,0,[]],\"changeTab\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"w-async\",[],[[\"@task\",\"@autoFire\"],[[24,[\"activeTab\",\"task\"]],true]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"component\",[[24,[\"activeTab\",\"component\"]]],[[\"runAttempt\",\"payload\",\"options\",\"class\"],[[24,[\"runAttempt\"]],[23,1,[]],[24,[\"activeTab\",\"options\"]],\"border-bottom-list my-5\"]]],false],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\\n\"],[4,\"if\",[[24,[\"pagination\"]]],null,{\"statements\":[[0,\"  \"],[5,\"w-pagination\",[],[[\"@pages\",\"@count\",\"@size\",\"@current\",\"@onchange\"],[[24,[\"pagination\",\"totalPages\"]],[24,[\"pagination\",\"count\"]],[22,\"limit\"],[24,[\"pagination\",\"currentPage\"]],[28,\"action\",[[23,0,[]],\"paginate\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/classroom/timeline/library/template.hbs"
    }
  });

  _exports.default = _default;
});