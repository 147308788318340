define("codingblocks-online/helpers/uppercase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.uppercase = uppercase;
  _exports.default = void 0;

  function uppercase(params) {
    return params[0].replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  var _default = Ember.Helper.helper(uppercase);

  _exports.default = _default;
});