define("codingblocks-online/pods/components/jobs-card/component", ["exports", "codingblocks-online/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      redirectToJob: function redirectToJob(jobId) {
        window.location = _environment.default.hiringblocksUrl + '/jobs/' + jobId;
      }
    }
  });

  _exports.default = _default;
});