define("codingblocks-online/pods/payment-webhook/route", ["exports", "codingblocks-online/config/environment", "codingblocks-online/utils/browser"], function (_exports, _environment, _browser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    loginUrl: "".concat(_environment.default.nuxtPublicUrl),
    session: Ember.inject.service(),
    api: Ember.inject.service(),
    queryParams: {
      transaction_id: {
        refreshModel: true
      },
      product_ids: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      if (JSON.parse(params.product_ids)[0].type == "extension") {
        return this.transitionTo('classroom');
      }

      if (this.get('session.isAuthenticated')) {
        return this.api.request('/run_attempts', {
          method: 'POST',
          data: {
            transaction_id: params.transaction_id,
            product_ids: JSON.parse(params.product_ids)
          }
        }).then(function (res) {
          _this.transitionTo('classroom');
        }).catch(function (err) {
          _this.transitionTo('error', {
            queryParams: {
              errorCode: 'PAYMENT_FAILED'
            }
          });
        });
      } else {
        localStorage.setItem('redirectionPath', window.location.pathname);
        window.location.href = this.loginUrl;
      }
    }
  });

  _exports.default = _default;
});