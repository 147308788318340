define("codingblocks-online/pods/components/locked-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pf75p43Z",
    "block": "{\"symbols\":[\"@course\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"p-5\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[5,\"fa-icon\",[],[[\"@icon\",\"@size\"],[\"lock\",\"3\"]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"mt-3\"],[8],[0,\"You need to buy this course to access this content\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"mt-3\"],[8],[0,\"\\n      \"],[7,\"a\",true],[11,\"href\",[29,[\"/courses/\",[23,1,[\"slug\"]]]]],[10,\"target\",\"_blank\"],[10,\"class\",\"button-solid button-orange\"],[8],[0,\" \\n        Buy Now \\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/locked-content/template.hbs"
    }
  });

  _exports.default = _default;
});