define("codingblocks-online/pods/components/unstarted-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5EJ9Hoi7",
    "block": "{\"symbols\":[\"@run\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n  \"],[5,\"fa-icon\",[],[[\"@icon\",\"@size\"],[\"lock\",\"3\"]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row justify-content-center my-3\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"white\"],[8],[0,\"Your course will start on \"],[1,[28,\"moment-format\",[[28,\"unix\",[[23,1,[\"start\"]]],null],\"Do MMM, YYYY\"],null],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/unstarted-content/template.hbs"
    }
  });

  _exports.default = _default;
});