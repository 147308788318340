define("codingblocks-online/pods/components/csv-submission-success/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8yYlS3pO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"c-csv-submission\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"mb-3\"],[8],[1,[22,\"name\"],false],[9],[0,\"\\n  \"],[7,\"hr\",true],[10,\"class\",\"mb-4\"],[8],[9],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"green\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"submission\",\"isPending\"]]],null,{\"statements\":[[0,\"      Calculating ....\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[24,[\"submission\",\"score\"]],false],[0,\"%\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mb-4\"],[8],[0,\"Your Answer’s Accuracy\"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"button-solid\"],[3,\"action\",[[23,0,[]],[24,[\"showOldSubmissions\"]]]],[8],[0,\"View Old Submissions\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/csv-submission-success/template.hbs"
    }
  });

  _exports.default = _default;
});