define("codingblocks-online/mixins/google-pageview", ["exports", "codingblocks-online/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    headData: Ember.inject.service(),
    metrics: Ember.inject.service(),
    userPageviewToGA: Ember.on('didTransition', function () {
      var page = this.url;
      var title = this.get('headData.title');

      if (Ember.get(_environment.default, 'googleAnalytics.webPropertyId') != null) {
        if (this.get('session.isAuthenticated')) {
          var userId = this.get('currentUser.user.oneauthId');
          window['ga']('set', 'userId', userId);
        } else {}

        window['ga']('send', 'pageview', {
          page: page,
          title: title
        });
        this.get('metrics').trackPage({
          page: page,
          title: title
        });
      }
    })
  });

  _exports.default = _default;
});