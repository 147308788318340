define("codingblocks-online/pods/classroom/timeline/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5i4fCVyQ",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"bread-crumbs\",null,[[\"tagName\",\"outputStyle\",\"linkable\"],[\"ul\",\"foundation\",true]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"revoked\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"border-card border-none bg-background-main-3\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"t-align-c\"],[8],[0,\"\\n      \"],[5,\"fa-icon\",[[12,\"class\",\"grey\"]],[[\"@icon\",\"@size\"],[\"info-circle\",\"5\"]]],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[10,\"class\",\"font-mds\"],[8],[0,\"\\n      Your access to this course has been revoked. If you think this is an error, Please contact \"],[7,\"a\",true],[10,\"href\",\"mailto:support@codingblocks.com\"],[10,\"class\",\"orange\"],[8],[0,\"support@codingblocks.com\"],[9],[0,\" with the following Debug Output\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"divider-h my-4\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"d-inline-block bold mb-2\"],[8],[0,\"\\n        Debug Output\\n      \"],[9],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n\\n      User ID: \"],[7,\"strong\",true],[8],[1,[24,[\"currentUser\",\"user\",\"id\"]],false],[9],[0,\" \"],[7,\"br\",true],[8],[9],[0,\"\\n      Oneauth ID: \"],[7,\"strong\",true],[8],[1,[24,[\"currentUser\",\"user\",\"oneauthId\"]],false],[0,\" \"],[9],[0,\" \"],[7,\"br\",true],[8],[9],[0,\"\\n      Run ID: \"],[7,\"strong\",true],[8],[1,[24,[\"model\",\"run\",\"id\"]],false],[0,\" \"],[9],[0,\" \"],[7,\"br\",true],[8],[9],[0,\"\\n      Run Attempt ID: \"],[7,\"strong\",true],[8],[1,[24,[\"model\",\"id\"]],false],[0,\" \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/classroom/timeline/template.hbs"
    }
  });

  _exports.default = _default;
});