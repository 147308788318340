define("codingblocks-online/adapters/application", ["exports", "ember-data", "codingblocks-online/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-case-declarations */
  var _default = _emberData.default.JSONAPIAdapter.extend({
    session: Ember.inject.service(),
    host: _environment.default.apiHost,
    namespace: 'api/v2',
    pathForType: function pathForType(type) {
      var original = this._super.apply(this, arguments);

      return Ember.String.underscore(original);
    },
    headers: Ember.computed('session.data.token', function () {
      var headers = {};
      var jwt = this.get('session.data.token');

      if (jwt) {
        headers['Authorization'] = "JWT ".concat(jwt);
      }

      return headers;
    }),
    urlForQueryRecord: function urlForQueryRecord(query) {
      if (query.custom) {
        switch (query.custom.ext) {
          case 'url':
            {
              var url = query.custom.url;
              delete query.custom;
              return "".concat(this._super.apply(this, arguments), "/").concat(url);
            }
        }
      } else {
        return this._super.apply(this, arguments);
      }
    },
    urlForQuery: function urlForQuery(query) {
      if (query.custom) {
        switch (query.custom.ext) {
          case 'url':
            {
              var url = query.custom.url;
              delete query.custom;
              return "".concat(this._super.apply(this, arguments), "/").concat(url);
            }
        }
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});