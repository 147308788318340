define("codingblocks-online/services/webengage", ["exports", "@babel/runtime/helpers/esm/objectSpread2"], function (_exports, _objectSpread2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    trackUser: function trackUser(user) {
      webengage.user.login(user.get('id'));
      webengage.user.setAttribute('ocb_email', user.get('email'));
      webengage.user.login(user.get('ocb_oneauth_id', user.get('oneauthId')));
    },
    trackEvent: function trackEvent(event_name, meta) {
      webengage.track(event_name, (0, _objectSpread2.default)({}, meta));
    }
  });

  _exports.default = _default;
});