define("codingblocks-online/helpers/format-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatPrice = formatPrice;
  _exports.default = void 0;

  function formatPrice(params
  /*, hash*/
  ) {
    var price = parseInt(params[0]);

    if (isNaN(price) || price == 0) {
      return "Free";
    } else {
      return "\u20B9 ".concat(price);
    }
  }

  var _default = Ember.Helper.helper(formatPrice);

  _exports.default = _default;
});