define("codingblocks-online/helpers/defaultPhoto", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaultPhoto = defaultPhoto;
  _exports.default = void 0;

  function defaultPhoto(params) {
    var _params = (0, _slicedToArray2.default)(params, 2),
        photo = _params[0],
        _params$ = _params[1],
        id = _params$ === void 0 ? 0 : _params$;

    if (photo) {
      return photo;
    }

    var defaultAvatarId = id % 35 + 1;
    return "https://minio.codingblocks.com/img/avatar-".concat(defaultAvatarId, ".svg");
  }

  var _default = Ember.Helper.helper(defaultPhoto);

  _exports.default = _default;
});