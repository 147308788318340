define("codingblocks-online/pods/components/loader-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/J2pMBSU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"element-wrapper\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"element\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"element\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"element\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"loading\"],[8],[0,\"Loading…\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"large-square\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/loader-component/template.hbs"
    }
  });

  _exports.default = _default;
});