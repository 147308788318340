define("codingblocks-online/models/doubt", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr(),
    body: _emberData.default.attr(),
    status: _emberData.default.attr(),
    content: _emberData.default.belongsTo('content'),
    runAttempt: _emberData.default.belongsTo('run-attempt'),
    category: _emberData.default.attr(),
    discourseTopicId: _emberData.default.attr(),
    firebaseRef: _emberData.default.attr(),
    comments: _emberData.default.hasMany('comment'),
    feedbacks: _emberData.default.hasMany('doubt-feedback'),
    resolvedById: _emberData.default.attr(),
    createdAt: _emberData.default.attr('date')
  });

  _exports.default = _default;
});