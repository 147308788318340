define("codingblocks-online/pods/components/testimonials-video/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g9PlGaTn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"c-testimonial-video\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"back-div bg-gradient-black-lr\"],[8],[9],[0,\"\\n  \"],[14,1],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"video-container\"],[8],[0,\"\\n      \"],[7,\"iframe\",true],[11,\"src\",[29,[[22,\"videoLink\"]]]],[10,\"frameborder\",\"0\"],[10,\"loading\",\"lazy\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/testimonials-video/template.hbs"
    }
  });

  _exports.default = _default;
});