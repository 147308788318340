define("codingblocks-online/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    // Add your transitions here, like:
    //   this.transition(
    //     this.fromRoute('people.index'),
    //     this.toRoute('people.detail'),
    //     this.use('toLeft'),
    //     this.reverse('toRight')
    //   );
    var tabs = ['classroom.timeline.overview', 'classroom.timeline.contents', 'classroom.timeline.announcements', 'classroom.timeline.doubts'];

    for (var i = 0; i < tabs.length; i++) {
      for (var j = i + 1; j < tabs.length; j++) {
        this.transition(this.fromRoute(tabs[i]), this.toRoute(tabs[j]), this.use('fade', {
          duration: 100
        }), this.reverse('fade', {
          duration: 100
        }));
      }
    } // this.transition(
    //   this.fromRoute('index'),
    //   this.toRoute('courses'),
    //   this.use('fade', { duration: 400 }),
    //   this.reverse('fade', { duration: 400 })
    // )


    this.transition(this.hasClass('slide'), // this makes our rule apply when the liquid-if transitions to the
    // true state.
    this.toValue(true), this.use('toLeft', {
      duration: 1
    }), // which means we can also apply a reverse rule for transitions to
    // the false state.
    this.reverse('crossFade', {
      duration: 1
    }));
  }
});