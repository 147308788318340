define("codingblocks-online/mixins/authenticated-route-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      // not calling this._super on purpose; we use our own redirectionPath
      if (!this.session.isAuthenticated) {
        localStorage.setItem('redirectionPath', window.location.pathname);
        this.router.transitionTo('application');
      }
    }
  });

  _exports.default = _default;
});