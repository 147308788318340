define("codingblocks-online/pods/components/dashboard/dashboard-courses-list-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3eqbOtTa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row no-gutters justify-content-between align-items-center mb-4\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-8 col-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row no-gutters align-items-center\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-2\"],[8],[0,\"\\n        \"],[7,\"img\",true],[11,\"src\",[29,[[24,[\"run\",\"course\",\"logo\"]]]]],[10,\"class\",\"course-list-logo\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-10 pl-xl-0 pl-3\"],[8],[0,\"\\n        \"],[7,\"h5\",true],[10,\"class\",\"bold\"],[8],[1,[24,[\"run\",\"course\",\"title\"]],false],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"mt-3\"],[8],[0,\"\\n          \"],[7,\"progress\",true],[11,\"value\",[29,[[24,[\"run\",\"topRunAttempt\",\"progressPercent\"]]]]],[10,\"max\",\"100\"],[10,\"class\",\"d-inline-block w-80 progress-orange\"],[8],[9],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"bold ml-3\"],[8],[1,[24,[\"run\",\"topRunAttempt\",\"progressPercent\"]],false],[0,\"%\"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"button-align-container mt-sm-0 mt-3\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"run\",\"topRunAttempt\",\"paused\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"button-dashed button-orange\"],[3,\"action\",[[23,0,[]],\"unpauseRunAttempt\"]],[8],[0,\"UnPause\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"link-to\",[[12,\"class\",\"button-orange button-solid\"]],[[\"@route\",\"@models\"],[\"classroom.timeline\",[28,\"array\",[[24,[\"run\",\"course\",\"id\"]],[24,[\"run\",\"id\"]]],null]]],{\"statements\":[[0,\"\\n      Resume\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/dashboard/dashboard-courses-list-item/template.hbs"
    }
  });

  _exports.default = _default;
});