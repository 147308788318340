define("codingblocks-online/mixins/utm-cookie-route", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/objectSpread2"], function (_exports, _defineProperty2, _objectSpread5) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Modified over: https://stackoverflow.com/questions/10730362/get-cookie-by-name
  function getCookie(name) {
    var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window.btoa('{}');
    var pattern = RegExp(name + "=.[^;]*");
    var matched = document.cookie.match(pattern);

    if (matched) {
      var cookie = matched[0].split('=');
      return cookie[1];
    }

    return defaultValue;
  }

  var utm_params = ['utm_campaign', 'utm_source', 'utm_medium', 'utm_term', 'utm_content', 'utm_coupon'];
  var resetProps = utm_params.reduce(function (acc, key) {
    return (0, _objectSpread5.default)((0, _objectSpread5.default)({}, acc), {}, (0, _defineProperty2.default)({}, key, null));
  }, {});
  var defaultQpProps = {
    replace: true
  };
  var queryParamsProps = utm_params.reduce(function (acc, key) {
    return (0, _objectSpread5.default)((0, _objectSpread5.default)({}, acc), {}, (0, _defineProperty2.default)({}, key, defaultQpProps));
  }, {});

  var _default = Ember.Mixin.create({
    queryParams: queryParamsProps,
    beforeModel: function beforeModel(transition) {
      var qp = transition.to.queryParams;
      var new_cbutm = Object.keys(qp).reduce(function (acc, key) {
        return utm_params.includes(key) && qp[key] ? (0, _objectSpread5.default)((0, _objectSpread5.default)({}, acc), {}, (0, _defineProperty2.default)({}, key, qp[key])) : acc;
      }, {});
      var old_cbutm = JSON.parse(window.atob(getCookie('_cbutm')));

      var _cbutm = window.btoa(JSON.stringify((0, _objectSpread5.default)((0, _objectSpread5.default)({}, old_cbutm), new_cbutm)));

      var expiry = moment().add("7", "days").toDate();
      document.cookie = "_cbutm=".concat(_cbutm, "; expires=").concat(expiry, "; path=/; domain=.codingblocks.com"); // document.cookie = `_cbutm=${_cbutm}; expires=${expiry}; path=/; domain=test.online` // in dev uncomment this

      this._super.apply(this, arguments);
    },
    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);

        this.controller.setProperties((0, _objectSpread5.default)((0, _objectSpread5.default)({}, resetProps), {}, {
          code: null
        }));
      }
    }
  });

  _exports.default = _default;
});