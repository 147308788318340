define("codingblocks-online/pods/attempt/content/quiz/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      startQuiz: function startQuiz() {
        var _this = this;

        this.get('model.newQuizAttempt').save().then(function (quizAttempt) {
          _this.transitionToRoute('attempt.content.quiz.attempt', quizAttempt.id, {
            queryParams: {
              s: _this.get('model.sectionId')
            }
          });
        });
      }
    }
  });

  _exports.default = _default;
});