define("codingblocks-online/models/hbcontent", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    details: _emberData.default.attr(),
    topSubmission: _emberData.default.belongsTo('submission'),
    "code-challenge": _emberData.default.belongsTo('code-challenge'),
    problem: _emberData.default.belongsTo('problem')
  });

  _exports.default = _default;
});