define("codingblocks-online/app", ["exports", "ember-resolver", "ember-load-initializers", "codingblocks-online/config/environment", "codingblocks-online/models/custom-inflector-rules"], function (_exports, _emberResolver, _emberLoadInitializers, _environment, _customInflectorRules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _emberResolver.default,
    engines: {
      hiringBlocks: {
        dependencies: {
          services: ['store', 'session', 'api', 'current-user', {
            'parent-router': 'router'
          }],
          externalRoutes: {
            login: 'login',
            course: 'courses.id'
          }
        }
      },
      cricketCup: {
        dependencies: {
          services: ['store', 'session', 'api', 'current-user', {
            'parent-router': 'router'
          }],
          externalRoutes: {
            login: 'login',
            courses: 'courses'
          }
        }
      }
    }
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});