define("codingblocks-online/adapters/section", ["exports", "codingblocks-online/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var baseUrl = this.buildURL(modelName, id, snapshot);
      return "".concat(baseUrl, "/?include=contents&exclude=contents.*&sort=content.section_content.order&public=true");
    }
  });

  _exports.default = _default;
});