define("codingblocks-online/pods/attempt/content/quiz/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        runAttempt: this.modelFor('attempt'),
        content: this.modelFor('attempt.content'),
        payload: this.modelFor('attempt.content').get('payload'),
        quiz: this.store.findRecord('quiz', params.quizId)
      });
    },
    setupController: function setupController(controller, model) {
      controller.set("sectionId", this.paramsFor('attempt').sectionId);
      controller.set("runAttempt", model.runAttempt);
      controller.set("run", model.runAttempt.get('run'));
      controller.set("content", model.content);
      controller.set("payload", model.payload);
    }
  });

  _exports.default = _default;
});