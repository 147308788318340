define("codingblocks-online/pods/components/csv/csv-downloads/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CJEVFPDI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mt-5\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[8],[0,\"\\n    \"],[5,\"fa-icon\",[[12,\"class\",\"gradient-text-orange\"]],[[\"@icon\"],[\"database\"]]],[0,\"\\n    Dataset\\n  \"],[9],[0,\"\\n  \\n  \"],[7,\"button\",false],[12,\"class\",\"button-solid button-orange mt-3\"],[3,\"action\",[[23,0,[]],[24,[\"download\"]],[24,[\"csv\",\"datasetUrl\"]]]],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"download\"]]],[0,\"\\n    Download\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"mt-5\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[8],[0,\"\\n    \"],[5,\"fa-icon\",[[12,\"class\",\"gradient-text-orange\"]],[[\"@icon\"],[\"file-alt\"]]],[0,\"\\n    Testcases\\n  \"],[9],[0,\"\\n  \\n  \"],[7,\"button\",false],[12,\"class\",\"button-solid button-orange mt-3\"],[3,\"action\",[[23,0,[]],[24,[\"download\"]],[24,[\"csv\",\"testcasesUrl\"]]]],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"download\"]]],[0,\"\\n    Download\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/csv/csv-downloads/template.hbs"
    }
  });

  _exports.default = _default;
});