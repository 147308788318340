define("codingblocks-online/models/cricket-cup-match", ["exports", "ember-data", "codingblocks-online/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr(),
    start: _emberData.default.attr(),
    description: _emberData.default.attr(),
    team1: _emberData.default.attr(),
    team2: _emberData.default.attr(),
    predictionEnd: _emberData.default.attr(),
    cricketCupQuestions: _emberData.default.hasMany('cricketCupQuestion'),
    resultDeclared: Ember.computed('cricketCupQuestions', function () {
      return !this.get('cricketCupQuestions').findBy('correctChoiceId', null);
    })
  });

  _exports.default = _default;
});