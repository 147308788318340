define("codingblocks-online/pods/components/file-upload-minio/component", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "codingblocks-online/config/environment", "ember-uploader/components/file-field", "ember-uploader/uploaders/uploader"], function (_exports, _toConsumableArray2, _environment, _fileField, _uploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fileField.default.extend({
    uploader: null,
    files: null,
    session: Ember.inject.service(),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var files = this.files,
          uploader = this.uploader,
          triggerUpload = this.triggerUpload;

      if (triggerUpload === true && !Ember.isEmpty(files)) {
        uploader.upload(files[0], {
          'extra': 'data'
        });
      } else {
        this.onError();
      }
    },
    filesDidChange: function filesDidChange(files) {
      var _this = this,
          _arguments = arguments;

      var jwt = this.get('session.data.token');

      var uploader = _uploader.default.create({
        url: "".concat(_environment.default.apiHost, "/api/minio/upload"),
        ajaxSettings: {
          headers: {
            'Authorization': "JWT ".concat(jwt)
          }
        }
      });

      if (this.onProgress) {
        uploader.on('progress', function (e) {
          _this.onProgress(e);
        });
      }

      uploader.on('didUpload', function (e) {
        _this.onComplete(e);
      });
      uploader.on('didError', function (jqXHR, textStatus, errorThrown) {
        _this.onError.apply(_this, (0, _toConsumableArray2.default)(_arguments));
      });
      this.set('uploader', uploader);
      this.set('files', files);
    }
  });

  _exports.default = _default;
});