define("codingblocks-online/models/web-challenge-submission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    source: _emberData.default.attr(),
    score: _emberData.default.attr(),
    isTopSubmission: _emberData.default.attr(),
    content: _emberData.default.belongsTo('content'),
    runAttempt: _emberData.default.belongsTo('run-attempt')
  });

  _exports.default = _default;
});