define("codingblocks-online/pods/attempt/content/video/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fJGPokj2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"iframe\",false],[12,\"id\",\"ext-video\"],[12,\"class\",\"c-video\"],[12,\"width\",\"100%\"],[12,\"src\",[29,[[28,\"embed\",[[24,[\"video\",\"url\"]]],null]]]],[12,\"frameborder\",\"0\"],[12,\"allowfullscreen\",\"\"],[3,\"did-insert\",[[28,\"action\",[[23,0,[]],[24,[\"initializePlayer\"]]],null]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/attempt/content/video/template.hbs"
    }
  });

  _exports.default = _default;
});