define("codingblocks-online/utils/session", ["exports", "codingblocks-online/config/environment", "codingblocks-online/utils/browser"], function (_exports, _environment, _browser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.forceLogin = void 0;

  /* Thanks to ~senpai~ :) */
  var forceLogin = function forceLogin() {
    var url = "".concat(_environment.default.oneauthURL, "/oauth/authorize?response_type=code&client_id=").concat(_environment.default.clientId, "&redirect_uri=").concat((0, _browser.getPublicUrl)());
    localStorage.setItem('redirectionPath', window.location.pathname.replace("/app", "/"));
    window.location.href = url;
  };

  _exports.forceLogin = forceLogin;
});