define("codingblocks-online/helpers/merge", ["exports", "hiring-blocks/helpers/merge"], function (_exports, _merge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _merge.default;
    }
  });
  Object.defineProperty(_exports, "merge", {
    enumerable: true,
    get: function get() {
      return _merge.merge;
    }
  });
});