define("codingblocks-online/pods/components/my-courses-list/run-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0YQhYHvr",
    "block": "{\"symbols\":[\"@run\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row no-gutters align-items-center rounded bg-background-main-3 my-3 br-10 p-4\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-lg-4 col-md-6\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row no-gutters align-items-center\"],[8],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"class\",\"s-60x60 br-5\"],[11,\"src\",[23,0,[\"course\",\"logo\"]]],[11,\"alt\",[23,0,[\"course\",\"title\"]]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"flex-1 pl-4\"],[8],[0,\"\\n        \"],[7,\"h5\",true],[10,\"class\",\"bold\"],[8],[0,\"\\n          \"],[1,[23,0,[\"course\",\"title\"]],false],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"br-50 d-inline-block p-2 bg-gradient-red-2\"],[10,\"style\",\"font-size: 10px;\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"font-normal mt-2\"],[8],[1,[23,0,[\"instrcutorNames\"]],false],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[28,\"not\",[[23,1,[\"topRunAttempt\",\"paused\"]]],null],[23,1,[\"topRunAttempt\",\"isExpired\"]]],null]],null,{\"statements\":[[0,\"            \"],[7,\"span\",true],[10,\"class\",\"red mt-1\"],[8],[0,\"| Expired\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"span\",true],[10,\"class\",\"mt-1 grey-3\"],[8],[0,\"| Expires on \"],[7,\"span\",true],[10,\"class\",\"bold\"],[8],[1,[23,0,[\"expiryDate\"]],false],[9],[0,\" \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"  \\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[14,2,[[23,1,[\"topRunAttempt\",\"progressPercent\"]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/my-courses-list/run-row/template.hbs"
    }
  });

  _exports.default = _default;
});