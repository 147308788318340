define("codingblocks-online/pods/tracks/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kT7ox6Er",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"layout\",\"outsideContainer\"]]],null,{\"statements\":[[4,\"in-element\",[[28,\"-clear-element\",[[24,[\"layout\",\"outsideContainer\"]]],null]],[[\"guid\",\"guid\",\"nextSibling\"],[\"%cursor:0%\",\"%cursor:0%\",null]],{\"statements\":[[0,\"      \"],[5,\"tracks/tracks-filter\",[],[[\"@status\",\"@professionId\",\"@onSearch\"],[[22,\"status\"],[22,\"professionId\"],[28,\"perform\",[[24,[\"onSearchTask\"]],[28,\"hash\",null,[[\"status\",\"professionId\"],[[24,[\"status\"]],[24,[\"professionId\"]]]]]],null]]]],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"divider-h my-3\"],[8],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[5,\"tracks/tracks-filter\",[],[[\"@status\",\"@professionId\",\"@onSearch\"],[[22,\"status\"],[22,\"professionId\"],[28,\"perform\",[[24,[\"onSearchTask\"]],[28,\"hash\",null,[[\"status\",\"professionId\"],[[24,[\"status\"]],[24,[\"professionId\"]]]]]],null]]]],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"divider-h\"],[8],[9],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/tracks/template.hbs"
    }
  });

  _exports.default = _default;
});