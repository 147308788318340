define("codingblocks-online/components/form-fields/range-field", ["exports", "ember-form-for/components/form-fields/range-field"], function (_exports, _rangeField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _rangeField.default;
    }
  });
});