define("codingblocks-online/pods/components/doubt-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IdPTUXYz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"pt-4 mb-2\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row no-gutters justify-content-between\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"img-desc align-items-center\"],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"src\",[29,[[28,\"if\",[[24,[\"photoUrl\"]],[24,[\"photoUrl\"]],\"https://minio.codingblocks.com/img/avatar-22.svg\"],null]]]],[10,\"alt\",\"\"],[10,\"class\",\"round s-30x30\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"description ml-3\"],[8],[0,\"\\n        \"],[7,\"h6\",true],[8],[1,[24,[\"topic\",\"username\"]],false],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"card-md grey\"],[8],[1,[28,\"moment-from-now\",[[24,[\"topic\",\"created_at\"]]],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"overflow-hidden\"],[8],[0,\"\\n    \"],[1,[24,[\"topic\",\"cooked\"]],true],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"mb-4\"],[8],[0,\"\\n    \"],[7,\"a\",true],[11,\"href\",[29,[[22,\"redirectLink\"]]]],[10,\"target\",\"_blank\"],[10,\"class\",\"red\"],[8],[0,\"\\n      \"],[7,\"button\",true],[10,\"class\",\"button-solid card-md mr-2\"],[8],[0,\"Reply\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"red card-md mr-3\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"topicResponse\",\"posts_count\"]],1],null]],null,{\"statements\":[[0,\"      \"],[1,[24,[\"topicResponse\",\"posts_count\"]],false],[0,\" Reply\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[24,[\"topicResponse\",\"posts_count\"]],false],[0,\" Replies\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/doubt-view/template.hbs"
    }
  });

  _exports.default = _default;
});