define("codingblocks-online/adapters/instructor", ["exports", "codingblocks-online/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      if (query.custom && query.custom.noResourceName) {
        var url = query.custom.url;
        var prefix = this.urlPrefix();
        delete query.custom;
        return "".concat(prefix, "/").concat(url);
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});