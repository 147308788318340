define("codingblocks-online/pods/courses/id/route", ["exports", "ember-data", "codingblocks-online/utils/seo"], function (_exports, _emberData, _seo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NotFoundError = _emberData.default.NotFoundError;

  var _default = Ember.Route.extend({
    headData: Ember.inject.service(),
    api: Ember.inject.service(),
    store: Ember.inject.service(),
    beforeModel: function beforeModel() {
      window.scrollTo(0, 0);
    },
    model: function model(params) {
      var _this = this;

      return this.store.findRecord("course", params.courseId).catch(function (err) {
        if (err instanceof NotFoundError) {
          _this.transitionTo('/404');
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set("course", model);
    },
    afterModel: function afterModel(course) {
      var head = this.headData;
      this.store.query('rating', {
        custom: {
          ext: 'url',
          url: "course/".concat(course.id)
        },
        page: {
          limit: 1
        }
      }).then(function (result) {
        head.set('schema', (0, _seo.getSeoSchemaForCourse)(course, result.toArray()));
      });
      head.setProperties({
        title: course.title,
        description: course.seoMeta,
        image: course.logo,
        url: 'https://online.codingblocks.com/courses/' + course.get('slug')
      });
    }
  });

  _exports.default = _default;
});