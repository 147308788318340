define("codingblocks-online/pods/components/discordinator-join-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UH91WacA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"shouldJoinDiscord\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\",true],[11,\"href\",[29,[[22,\"redirectUrl\"]]]],[10,\"target\",\"_blank\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"border-card border-none bg-gradient-violet-bt mb-5 white p-4 v-align-ma mt-md-0 mt-5 row no-gutters align-items-center\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fab fa-discord fa-2x mr-2\"],[8],[9],[0,\"\\n      \"],[7,\"strong\",true],[10,\"class\",\"font-sm flex-1 pl-1\"],[8],[0,\"Join Discord Group\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/discordinator-join-button/template.hbs"
    }
  });

  _exports.default = _default;
});