define("codingblocks-online/models/course", ["exports", "ember-data", "codingblocks-online/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    title: _emberData.default.attr(),
    subtitle: _emberData.default.attr(),
    summary: _emberData.default.attr(),
    fees: _emberData.default.attr(),
    promoVideo: _emberData.default.attr(),
    coverImage: _emberData.default.attr(),
    logo: _emberData.default.attr(),
    badgeUrl: _emberData.default.attr(),
    language: _emberData.default.attr(),
    faq: _emberData.default.attr(),
    slug: _emberData.default.attr('string'),
    difficulty: _emberData.default.attr('number'),
    categoryName: _emberData.default.attr(),
    categoryId: _emberData.default.attr('number'),
    doubtSubCategoryId: _emberData.default.attr('number'),
    price: Ember.computed('fees', 'isFree', function () {
      if (this.isFree) return 0;else return this.fees.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }),
    popularity: _emberData.default.attr(),
    hoursPerDay: _emberData.default.attr(),
    isFree: _emberData.default.attr(),
    duration: _emberData.default.attr(),
    lecturesCount: _emberData.default.attr(),
    reviewCount: _emberData.default.attr(),
    videosDuration: _emberData.default.attr(),
    type: _emberData.default.attr(),
    color: _emberData.default.attr(),
    buyNowLink: _emberData.default.attr(),
    backgroundImage: _emberData.default.attr(),
    rating: _emberData.default.attr('number'),
    seoMeta: _emberData.default.attr(),
    code: _emberData.default.attr(),
    goldenLogo: _emberData.default.attr(),
    topRun: Ember.computed('activeRuns', 'runs', function () {
      var runs = this.activeRuns; // if we don't have activeRuns

      if (Ember.isNone(runs) || !runs.get('length')) {
        runs = this.runs;
      }

      var now = +new Date() / 1000.0;
      var currentRuns = runs.filter(function (run, index) {
        return run.get('enrollmentStart') < now && run.get('enrollmentEnd') > now && !run.get('unlisted');
      });
      return currentRuns.sortBy('price').objectAt(0) || runs.sortBy('price').objectAt(0);
    }),
    runs: _emberData.default.hasMany('run'),
    // currentRun: DS.belongsTo('run', { inverse: 'course'}),
    activeRuns: _emberData.default.hasMany('run', {
      inverse: null
    }),
    instructors: _emberData.default.hasMany('instructor'),
    feedbacks: _emberData.default.hasMany('feedback'),
    feedback: Ember.computed('feedbacks', function () {
      return this.feedbacks.objectAt(0);
    }),
    difficultyName: Ember.computed('difficulty', function () {
      switch (+this.difficulty) {
        case 0:
          return 'beginner';
          break;

        case 1:
          return 'advanced';
          break;

        case 2:
          return 'expert';
          break;

        default:
          return 'beginner';
          break;
      }
    }),
    identifier: Ember.computed('slug', 'id', function () {
      return this.slug || this.id;
    }),
    ratings: _emberData.default.hasMany('rating'),
    ratingCarousel: Ember.computed('ratings', function () {
      return this.ratings.map(function (rating) {
        if (Ember.isEmpty(rating.get('heading')) && Ember.isEmpty(rating.get('review'))) {
          rating.setProperties({
            shown: false
          });
        } else {
          rating.setProperties({
            shown: true
          });
        }

        return rating;
      });
    }),
    userRating: Ember.computed('ratings', function () {
      return this.ratings.objectAt(0);
    }),
    organization: _emberData.default.attr(),
    coursefeatures: _emberData.default.attr(),
    projects: _emberData.default.hasMany('projects'),
    tags: _emberData.default.hasMany("tag"),
    latestRun: Ember.computed('runs', 'runs.topRunAttempt', function () {
      return this.get('runs').filter(function (run) {
        return run.get('topRunAttempt');
      })[0];
    }),
    userCourseWishlist: _emberData.default.belongsTo('userCourseWishlist')
  });

  _exports.default = _default;
});