define("codingblocks-online/pods/components/dashboard/dashboard-applied-jobs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/tnJIhAz",
    "block": "{\"symbols\":[\"job\"],\"statements\":[[4,\"each\",[[24,[\"jobs\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"hover-grey py-4 px-lg-5 px-4\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"d-flex align-items-center\"],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"src\",[29,[[23,1,[\"company\",\"logo\"]]]]],[10,\"class\",\"s-60x60 mr-3\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"h5\",true],[10,\"class\",\"bold\"],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n        \"],[7,\"span\",true],[8],[1,[23,1,[\"company\",\"name\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/dashboard/dashboard-applied-jobs/template.hbs"
    }
  });

  _exports.default = _default;
});