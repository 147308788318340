define("codingblocks-online/pods/components/offerings-static/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/4Nz14FY",
    "block": "{\"symbols\":[\"offering\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row m-5 justify-content-center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"font-xl bold\"],[8],[0,\"\\n    What we offer\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row c-offerings\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"offset-md-1\"],[8],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"offerings\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"t-align-ca col-12 col-sm-3 col-md-2 mb-5\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"border-card p-4\"],[8],[0,\"\\n        \"],[7,\"img\",true],[11,\"src\",[23,1,[\"img\"]]],[10,\"loading\",\"lazy\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \\n      \"],[7,\"div\",true],[10,\"class\",\"heading\"],[8],[0,\"\\n        \"],[1,[23,1,[\"name\"]],true],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"card-body grey\"],[8],[0,\"\\n        \"],[1,[23,1,[\"body\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/offerings-static/template.hbs"
    }
  });

  _exports.default = _default;
});