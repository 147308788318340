define("codingblocks-online/pods/components/recommended-courses/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WnSUlRnf",
    "block": "{\"symbols\":[\"card\",\"course\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"t-align-c mb-5 font-xl bold\"],[8],[0,\"\\n  Recommended Courses\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"fetchRecommendedCoursesTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[5,\"loading-card\",[[12,\"class\",\"w-100\"]],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"card-grid\",[[12,\"class\",\"row justify-content-center\"]],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"recommendedCourses\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"c-layout-card col-12 col-md-6 col-lg-4 pr-md-0\"],[8],[0,\"\\n      \"],[1,[28,\"course-card\",null,[[\"course\",\"index\",\"showPopUp\",\"onChangePopped\",\"organization\"],[[23,2,[]],[23,3,[]],[28,\"eq\",[[23,3,[]],[23,1,[\"poppedIndex\"]]],null],[23,1,[\"changePoppedCard\"]],[24,[\"organization\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[2,3]},null],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/recommended-courses/template.hbs"
    }
  });

  _exports.default = _default;
});