define("codingblocks-online/pods/components/course-library/bookmarks/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u4Gataau",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"col-12 col-md-4 col-lg-3 gradient-text-orange font-sm d-flex bold\"]],[[\"@route\",\"@models\"],[\"attempt.content\",[28,\"array\",[[24,[\"bookmark\",\"runAttempt\",\"id\"]],[24,[\"bookmark\",\"section\",\"id\"]],[24,[\"bookmark\",\"content\",\"id\"]]],null]]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[8],[0,\" \"],[1,[24,[\"bookmark\",\"content\",\"title\"]],false],[0,\" \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-6 col-md-3 grey\"],[8],[0,\"\\n    \"],[1,[28,\"moment-from-now\",[[24,[\"bookmark\",\"createdAt\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"col-6 col-md-2 ml-auto gradient-text-orange t-align-r pointer\"],[3,\"action\",[[23,0,[]],\"deleteBookmark\"]],[8],[0,\"\\n    Delete\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"my-4 font-sm t-align-j\"],[8],[0,\"\\n  Section: \"],[1,[24,[\"bookmark\",\"section\",\"name\"]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/course-library/bookmarks/row/template.hbs"
    }
  });

  _exports.default = _default;
});