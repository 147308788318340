define("codingblocks-online/pods/payment-webhook/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['transaction_id', 'product_ids'],
    transaction_id: null,
    product_ids: null
  });

  _exports.default = _default;
});