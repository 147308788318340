define("codingblocks-online/pods/attempt/content/document/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HAg+Tjnh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid c-document\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row py-4 bg-black-grey justify-content-between\"],[10,\"style\",\"border-top-right-radius: 15px; border-top-left-radius: 15px\"],[8],[0,\"\\n      \\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-9  gradient-text-orange font-xl\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"pl-2\"],[8],[1,[24,[\"document\",\"name\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-3\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"pr-3 t-align-r\"],[8],[0,\"\\n          \"],[7,\"a\",true],[11,\"href\",[24,[\"document\",\"pdfLink\"]]],[10,\"target\",\"_blank\"],[10,\"class\",\"white button-solid button-orange\"],[8],[0,\"\\n            Download as PDF  \\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row markdown\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"document\",\"markdown\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"markdown-to-html\",[[24,[\"document\",\"markdown\"]]],[[\"extensions\",\"class\"],[\"katex\",\"my-2 font-sm px-4\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"iframe\",true],[11,\"src\",[29,[\"https://docs.google.com/gview?url=\",[24,[\"document\",\"pdfLink\"]],\"&embedded=true\"]]],[10,\"style\",\"width:100%\"],[10,\"frameborder\",\"0\"],[8],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/attempt/content/document/template.hbs"
    }
  });

  _exports.default = _default;
});