define("codingblocks-online/pods/attempt/content/quiz/attempt/done/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        quizAttempt: this.modelFor('attempt.content.quiz.attempt'),
        quiz: this.modelFor('attempt.content.quiz').quiz
      });
    },
    setupController: function setupController(controller, model) {
      controller.set("quizAttempt", model.quizAttempt);
      controller.set("quiz", model.quiz);
    }
  });

  _exports.default = _default;
});