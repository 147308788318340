define("codingblocks-online/pods/components/difficulty-dots/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TyLW+Ogi",
    "block": "{\"symbols\":[\"index\"],\"statements\":[[2,\"difficulty: {type: integer, description: to show the difficulty of course}\"],[0,\"\\n\"],[2,\"difficultyName: {type: string, description: the level of difficulty\"],[0,\"\\n\"],[4,\"each\",[[28,\"range\",[0,3],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"font-lg pl-1 \",[28,\"if\",[[28,\"lte\",[[23,1,[]],[24,[\"difficulty\"]]],null],\"orange\",\"grey\"],null]]]],[8],[0,\"•\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[7,\"div\",true],[10,\"class\",\"card-sms\"],[8],[1,[28,\"uppercase\",[[24,[\"difficultyName\"]]],null],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/difficulty-dots/template.hbs"
    }
  });

  _exports.default = _default;
});