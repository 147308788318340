define("codingblocks-online/pods/components/doubt-feedback/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ICMym0z3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"body t-align-c p-5 white\"],[8],[0,\"\\n  \"],[7,\"img\",true],[10,\"class\",\"mb-3\"],[10,\"src\",\"https://minio.codingblocks.com/amoeba/yellow-tick.svg\"],[10,\"width\",\"33\"],[10,\"height\",\"33\"],[10,\"alt\",\"doubt resolved\"],[8],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"title yellow\"],[8],[0,\"\\n    Your doubt has been resolved.\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[0,\"\\n    \"],[1,[24,[\"doubt\",\"title\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"rating-bar\",[[12,\"class\",\"stars my-3 w-100\"]],[[\"@scale\",\"@rating\",\"@changeRating\"],[5,[22,\"score\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"score\"]]],null]],null]]]],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"d-flex align-items-center justify-content-between\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"w-80\"],[8],[0,\"\\n      \"],[5,\"textarea\",[[12,\"class\",\"w-100 underline-input\"],[12,\"placeholder\",\"Please leave your feedback. It's optional.\"]],[[\"@value\"],[[22,\"description\"]]]],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"ml-2 red\"],[12,\"disabled\",[24,[\"submitFeedback\",\"isRunning\"]]],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"submitFeedback\"]]],null]]],[8],[0,\"\\n        \"],[1,[28,\"if\",[[24,[\"submitFeedback\",\"isRunning\"]],\"Submitting\",\"Submit\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/doubt-feedback/template.hbs"
    }
  });

  _exports.default = _default;
});