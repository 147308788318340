define("codingblocks-online/templates/components/code-editor", ["exports", "ember-monaco/templates/components/code-editor"], function (_exports, _codeEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _codeEditor.default;
    }
  });
});