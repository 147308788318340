define("codingblocks-online/pods/components/course-library/notes/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n4uSkSGh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"col-12 col-md-5 gradient-text-orange font-sm d-flex bold\"]],[[\"@route\",\"@models\"],[\"attempt.content\",[28,\"array\",[[24,[\"note\",\"runAttempt\",\"id\"]],[24,[\"note\",\"section\",\"id\"]],[24,[\"note\",\"content\",\"id\"]]],null]]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[8],[0,\" \"],[1,[24,[\"note\",\"content\",\"title\"]],false],[0,\" \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ml-auto mx-md-auto pl-2\"],[8],[0,\"\\n        \"],[1,[28,\"format-video-time\",[[24,[\"note\",\"duration\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-6 col-md-3 grey\"],[8],[0,\"\\n    \"],[1,[28,\"moment-from-now\",[[24,[\"note\",\"createdAt\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"col-6 col-md-2 ml-auto gradient-text-orange t-align-r pointer\"],[3,\"action\",[[23,0,[]],\"changeDeletedStatus\"]],[8],[0,\"\\n    \"],[1,[22,\"buttonText\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"my-4 t-align-j\"],[8],[0,\"\\n  \"],[1,[24,[\"note\",\"text\"]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/course-library/notes/row/template.hbs"
    }
  });

  _exports.default = _default;
});