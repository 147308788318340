define("codingblocks-online/pods/components/card-grid/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4sbeu5kr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"poppedIndex\",\"changePoppedCard\"],[[24,[\"poppedIndex\"]],[28,\"action\",[[23,0,[]],\"changePoppedCard\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/card-grid/template.hbs"
    }
  });

  _exports.default = _default;
});