define("codingblocks-online/services/lecture-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    element: null,
    active: false,
    setElement: function setElement(el) {
      this.element = el;
    },
    activate: function activate() {
      this.set('active', true);
    },
    deactivate: function deactivate() {
      this.set('active', false);
    },
    getCurrentTime: function getCurrentTime() {
      return this.element.currentTime;
    },
    seek: function seek(time) {
      if (!this.element) {
        throw new Error('Cannot seek lecture-player, no element is present!');
      }

      this.element.currentTime = time;
    }
  });

  _exports.default = _default;
});