define("codingblocks-online/models/announcement", ["exports", "ember-data", "ember-moment/computeds/moment", "ember-moment/computeds/from-now"], function (_exports, _emberData, _moment2, _fromNow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr("string"),
    text: _emberData.default.attr("string"),
    createdAt: _emberData.default.attr(),
    updatedAt: _emberData.default.attr(),
    run: _emberData.default.belongsTo('run'),
    user: _emberData.default.belongsTo('user'),
    elapsedTime: (0, _fromNow.default)((0, _moment2.default)('createdAt'), false)
  });

  _exports.default = _default;
});