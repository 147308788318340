define("codingblocks-online/pods/nagarro/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    activate: function activate() {
      this._super.apply(this, arguments);

      this.currentUser.setOrg('nagarro');
      window.localStorage.setItem('org', 'nagarro');
    }
  });

  _exports.default = _default;
});