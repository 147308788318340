define("codingblocks-online/data/content-player", ["exports", "codingblocks-online/data/buttons"], function (_exports, _buttons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var onMobile = window.innerWidth <= 800;
  var _default = [{
    attachTo: {
      element: '#contentScrollContainer',
      on: 'right'
    },
    buttons: [_buttons.default.cancel, _buttons.default.next],
    title: 'Table of Contents',
    text: "\n      <p> See the whole list of course contents here\n      </p>\n    "
  }, {
    attachTo: {
      element: '#bookmark-content',
      on: 'left'
    },
    buttons: [_buttons.default.cancel, _buttons.default.next],
    title: 'Bookmark your favourite content',
    text: "\n      <p>\n        Bookmark the particular section of the course you'd like to watch again and access it directly from your course library\n      </p>\n    "
  }, {
    attachTo: {
      element: '#content-searchbox',
      on: 'bottom'
    },
    buttons: [_buttons.default.cancel, _buttons.default.next],
    title: 'You can search contents here',
    text: "\n      <p> Click and type here to search the section video you want to watch\n      </p>\n    "
  }, {
    attachTo: {
      element: '#ask-doubt',
      on: 'left'
    },
    buttons: [_buttons.default.cancel, _buttons.default.next],
    title: 'Ask your doubts here!',
    text: "\n      <p> Scroll down here to ask your doubt/ raise your query\n      </p>\n    "
  }, {
    attachTo: {
      element: '#start-player-tour',
      on: 'left'
    },
    buttons: [_buttons.default.cancel],
    title: 'Restart Tour',
    highlightClass: 'px-3',
    text: "\n      <p>\n       Click here to restart this tour anytime.\n      </p>\n    "
  }];
  _exports.default = _default;
});