define("codingblocks-online/models/submission", ["exports", "ember-data", "codingblocks-online/utils/testcases", "codingblocks-online/utils/functional"], function (_exports, _emberData, _testcases, _functional) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    language: _emberData.default.attr(),
    score: _emberData.default.attr(),
    solution: _emberData.default.attr(),
    source: Ember.computed('solution', function () {
      return this.solution.source;
    }),
    explanation: _emberData.default.attr(),
    'submit-at': _emberData.default.attr(),
    'judge-result': _emberData.default.attr(),
    sourceParsed: Ember.computed('source', function () {
      return window.atob(this.source);
    }),
    isErrored: Ember.computed('judge-result', function () {
      return this['judge-result'].result !== 'success';
    }),
    errorMessage: Ember.computed('judge-result', 'isErrored', function () {
      if (!this.isErrored) {
        return '';
      }

      switch (this['judge-result'].result) {
        case 'compile_error':
          return 'Compilation Error';
      }
    }),
    passedTestCasesArray: Ember.computed('judge-result', function () {
      var results = this['judge-result'];

      if (!(0, _testcases.isValidResult)(results)) {
        return [];
      }

      var passedTestcases = results.data.testcases.filter(_testcases.isPassedTestcase);
      return passedTestcases;
    }),
    passedTestCases: Ember.computed('passedTestCasesArray', function () {
      var passedTestcases = this.passedTestCasesArray;
      return passedTestcases.length ? passedTestcases.map(function (tc, index) {
        return index + 1;
      }).toString() : '[]';
    }),
    failedTestCasesArray: Ember.computed('judge-result', function () {
      var results = this['judge-result'];
      if (!(0, _testcases.isValidResult)(results)) return [];
      var failedTestCases = results.data.testcases.filter((0, _functional.not)(_testcases.isPassedTestcase));
      return failedTestCases;
    }),
    failedTestCases: Ember.computed('failedTestCasesArray', function () {
      var failedTestCases = this.failedTestCasesArray;
      return failedTestCases.length ? failedTestCases.map(function (tc, index) {
        return index + 1;
      }).toString() : '[]';
    }),
    executionTime: Ember.computed('judge-result', function () {
      var testcases = this.get('judge-result.data.testcases');
      if (!testcases) return '--';
      return testcases.reduce(function (acc, t) {
        return acc + +t.runtime;
      }, 0).toFixed(2);
    }),
    resultParams: Ember.computed('explanation', 'submissionType', function () {
      switch (this.explanation) {
        case 'Perfect':
          return {
            color: 'green',
            icon: 'check',
            message: 'Accepted'
          };

        case 'FailedTestcase':
          return {
            color: 'red',
            icon: 'times',
            message: 'Wrong Answer'
          };

        case 'TimeLimitExceeded':
          return {
            color: 'orange',
            icon: 'exclamation',
            message: 'TLE'
          };

        case 'CompilationError':
          return {
            color: 'orange',
            icon: 'exclamation',
            message: 'Compilation Error'
          };

        case 'ContestOver':
          return {
            color: 'orange',
            icon: 'exclamation',
            message: 'Contest Over'
          };

        case 'TestcaseUnlocked':
          return {
            color: 'orange',
            icon: 'exclamation',
            message: 'Test Case Unlocked'
          };

        case 'EditorialUnlocked':
          return {
            color: 'orange',
            icon: 'exclamation',
            message: 'Editorial Unlocked'
          };

        default:
          return {
            color: 'orange',
            icon: 'exclamation',
            message: 'Submission Not Judged'
          };
      }
    }),
    user: _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});