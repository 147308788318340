define("codingblocks-online/models/spin", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    valid_till: _emberData.default.attr(),
    used: _emberData.default.attr(),
    used_at: _emberData.default.attr('date'),
    won: _emberData.default.attr(),
    prize_remarks_extra: _emberData.default.attr(),
    user: _emberData.default.belongsTo('user'),
    spinPrize: _emberData.default.belongsTo('spin_prize')
  });

  _exports.default = _default;
});