define("codingblocks-online/pods/components/goodie-comp/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    api: Ember.inject.service(),
    router: Ember.inject.service(),
    isDisabled: false,
    showModal: false,
    collapsed: true,
    tshirt: "",
    progressPercent: Ember.computed.alias("runAttempt.progressPercent"),
    run: Ember.computed.alias("runAttempt.run"),
    goodieRequests: Ember.computed.alias("runAttempt.goodieRequests"),
    statusInProgress: Ember.computed.alias("goodieRequests.statusInProgress"),
    thresholdCompleted: Ember.computed("statusInProgress", "alreadyClaimed", "progressPercent", "run.goodiesThreshold", function () {
      return this.progressPercent > this.get("run.goodiesThreshold");
    }),
    canClaim: Ember.computed.alias("thresholdCompleted"),
    alreadyClaimed: Ember.computed.equal("statusInProgress", "requested"),
    completed: Ember.computed.equal("statusInProgress", "completed"),
    actions: {
      toggleCollapse: function toggleCollapse() {
        this.toggleProperty("collapsed");
      },
      toggleModal: function toggleModal() {
        this.toggleProperty("showModal");
      },
      saveForm: function saveForm() {
        var _this = this;

        this.toggleProperty("isDisabled");
        var formInfo = {
          name: this.get("name"),
          tshirt: this.tshirt,
          address: this.get("address").replace(/\n/g, " "),
          postalCode: this.postalCode,
          alternateContact: this.mobile
        };
        Ember.setProperties(this.goodieRequests, {
          formInfo: formInfo,
          statusInProgress: "requested",
          run: this.runAttempt.run,
          runAttempt: this.runAttempt
        });
        this.get("goodieRequests").save().then(function () {
          _this.toggleProperty("showModal");
        });
        this.toggleProperty("isDisabled");
      }
    }
  });

  _exports.default = _default;
});