define("codingblocks-online/pods/components/player/player-section-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xs2s/x52",
    "block": "{\"symbols\":[\"section\",\"@mode\"],\"statements\":[[4,\"each\",[[24,[\"sections\"]]],null,{\"statements\":[[0,\"      \"],[5,\"player/player-section-list-item\",[],[[\"@section\",\"@isOpen\",\"@mode\"],[[23,1,[]],[28,\"eq\",[[24,[\"initialSectionId\"]],[23,1,[\"id\"]]],null],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/player/player-section-list/template.hbs"
    }
  });

  _exports.default = _default;
});