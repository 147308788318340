define("codingblocks-online/pods/components/editor-collab-help/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3IFx9uac",
    "block": "{\"symbols\":[\"@onClose\"],\"statements\":[[5,\"modal-dialog\",[],[[\"@translucentOverlay\",\"@targetAttachment\",\"@containerClass\",\"@overlayClass\",\"@wrapperClass\",\"@onClose\"],[true,\"none\",\"centered-scrolling-container p-4 pb-5 bg-dark-grey\",\"centered-scrolling-overlay\",\"centered-scrolling-wrapper\",[28,\"action\",[[23,0,[]],[23,1,[]]],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"white\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"What is Collborate Mode?\"],[9],[0,\"\\n    \"],[7,\"hr\",true],[8],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      When you ask a doubt related to programming problem, you can use \\n      \"],[7,\"b\",true],[8],[0,\"Collaborate Mode\"],[9],[0,\" to share your code editor with a TA. \\n      With the collaborate mode on, you and your TA can collaborate in realtime to \\n      debug your code and help you solve the challenge.\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[8],[0,\"\\n      You can switch back to \"],[7,\"b\",true],[8],[0,\"Normal Mode\"],[9],[0,\" anytime and your editor\\n      will no longer be connected to your TA.\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"em\",true],[10,\"class\",\"bold font-mds\"],[8],[0,\"\\n      TL;DR: Keep \"],[7,\"b\",true],[8],[0,\"Collaborate Mode\"],[9],[0,\" when you want to share your code with\\n      TA in realtime. Use \"],[7,\"b\",true],[8],[0,\"Normal Mode\"],[9],[0,\" when you are working on your own. \\n    \"],[9],[0,\"\\n\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"button-solid button-orange mt-4\"],[3,\"action\",[[23,0,[]],[23,1,[]]]],[8],[0,\"Got it\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/editor-collab-help/template.hbs"
    }
  });

  _exports.default = _default;
});