define("codingblocks-online/templates/components/page-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OWZqN/pk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-progress\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"bar\"],[10,\"data-test-id\",\"data-test-page-progress-bar\"],[11,\"style\",[22,\"barStyle\"]],[8],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/templates/components/page-progress.hbs"
    }
  });

  _exports.default = _default;
});