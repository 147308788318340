define("codingblocks-online/pods/attempt/content/quiz/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xKfBue8T",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"p-3 c-qna\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"bold\"],[8],[1,[24,[\"quiz\",\"title\"]],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"font-sm mt-3\"],[8],[0,\"\\n    Total Questions: \"],[1,[24,[\"quiz\",\"questions\",\"length\"]],false],[0,\" | Maximum Marks: \"],[1,[28,\"multiply\",[[24,[\"quiz\",\"questions\",\"length\"]],10],null],false],[0,\" | Question Type: MCQ\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mt-5\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"button-solid button-orange\"],[3,\"action\",[[23,0,[]],\"startQuiz\"]],[8],[0,\"\\n      Start Quiz\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/attempt/content/quiz/index/template.hbs"
    }
  });

  _exports.default = _default;
});