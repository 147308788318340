define("codingblocks-online/adapters/cricket-cup-user-prediction", ["exports", "codingblocks-online/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'api/v2/cricket_cup',
    pathForType: function pathForType() {
      return 'user_predictions';
    }
  });

  _exports.default = _default;
});