define("codingblocks-online/pods/components/referral-code/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XSm6iJR7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[28,\"or\",[[24,[\"getReferralCodeTask\",\"last\",\"isError\"]],[24,[\"getReferralCodeTask\",\"isRunning\"]]],null]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"c-referral-code\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"coupon\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[0,\"Refer and Earn: \"],[9],[0,\"\\n    \"],[7,\"div\",false],[12,\"id\",\"coupon-code\"],[12,\"class\",\"code\"],[3,\"action\",[[23,0,[]],\"selectText\",\"coupon-code\"]],[8],[0,\"\\n      https://codingblocks.com/join/\"],[1,[22,\"code\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"description col-12 col-md-7\"],[3,\"action\",[[23,0,[]],\"selectText\",\"coupon-code\"]],[8],[0,\"\\n    This is your referral link and code. Get your friends to sign up using this link and gift them Rs. 500 in their wallet. We'll gift you Rs. 500 if they purchase a course. or you can share your referral code directly. For every friend who purchases a course using your code during the checkout, you earn Rs. 500 credit in your wallet. Click code to copy.\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"shares\"],[8],[0,\"\\n    \"],[7,\"span\",false],[3,\"action\",[[23,0,[]],\"shareFacebook\"]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fab fa-facebook-square\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",false],[3,\"action\",[[23,0,[]],\"shareTwitter\"]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fab fa-twitter-square\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",false],[3,\"action\",[[23,0,[]],\"shareWhatsapp\"]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fab fa-whatsapp-square\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/referral-code/template.hbs"
    }
  });

  _exports.default = _default;
});