define("codingblocks-online/pods/components/blockers/upgrade-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UjciHBcW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"show\"]]],null,{\"statements\":[[0,\"  \"],[5,\"modal-dialog\",[],[[\"@onClose\",\"@translucentOverlay\",\"@targetAttachment\",\"@containerClass\",\"@overlayClass\",\"@wrapperClass\"],[[22,\"close\"],\"true\",\"none\",\"centered-scrolling-container\",\"centered-scrolling-overlay\",\"centered-scrolling-wrapper\"]],{\"statements\":[[0,\"\\n    \"],[5,\"blockers/upgrade\",[],[[\"@courseId\",\"@runAttempt\",\"@close\"],[[22,\"courseId\"],[22,\"runAttempt\"],[22,\"close\"]]],{\"statements\":[[0,\"\\n\\n      \"],[14,1],[0,\"\\n      \\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/blockers/upgrade-modal/template.hbs"
    }
  });

  _exports.default = _default;
});