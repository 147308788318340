define("codingblocks-online/pods/components/otp-enroll/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rdYjTXRi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row align-items-center no-gutters justify-content-between py-4 px-lg-5 px-4 bg-background-main-3\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex-1\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"t-align-md-l t-align-c\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"font-sm bold\"],[8],[0,\"ENROLL TO COURSES VIA OTP\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"red mt-1\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"otpSent\"]]],null,{\"statements\":[[0,\"        We have sent an OTP to your registered Email\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"errorString\"]]],null,{\"statements\":[[0,\"        \"],[1,[22,\"errorString\"],false],[0,\"\\n        \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12 col-md-7 mt-md-0 mt-4\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row justify-content-center justify-content-lg-end no-gutters align-items-center\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"flex-1\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"search-bar w-100 bg-background-main-2\"],[8],[0,\"\\n          \"],[1,[28,\"input\",null,[[\"type\",\"class\",\"placeholder\",\"value\"],[\"text\",\"bg-background-main-2 white\",[24,[\"placeholderText\"]],[24,[\"userInput\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ml-4\"],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"button-solid button-orange\"],[3,\"action\",[[23,0,[]],\"handleClick\"]],[8],[0,\"\\n          \"],[1,[22,\"buttonText\"],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/otp-enroll/template.hbs"
    }
  });

  _exports.default = _default;
});