define("codingblocks-online/models/goodie-request", ["exports", "ember-data", "codingblocks-online/models/run"], function (_exports, _emberData, _run) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    statusInProgress: _emberData.default.attr(),
    formInfo: _emberData.default.attr(),
    runAttempt: _emberData.default.belongsTo('run-attempt'),
    run: _emberData.default.belongsTo('run')
  });

  _exports.default = _default;
});