define("codingblocks-online/pods/components/my-courses-list/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FqfX44/U",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"d-flex align-items-center\"],[8],[0,\"\\n  \"],[7,\"progress\",true],[11,\"value\",[22,\"percent\"]],[10,\"max\",\"100\"],[11,\"class\",[29,[\"flex-1 \",[22,\"progressBarClass\"]]]],[8],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"bold ml-3\"],[8],[1,[22,\"percent\"],false],[0,\"%\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/my-courses-list/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});