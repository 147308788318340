define("codingblocks-online/models/run", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    api: Ember.inject.service(),
    name: _emberData.default.attr(),
    start: _emberData.default.attr(),
    end: _emberData.default.attr(),
    enrollmentStart: _emberData.default.attr(),
    enrollmentEnd: _emberData.default.attr(),
    isFree: _emberData.default.attr(),
    price: _emberData.default.attr("number"),
    mrp: _emberData.default.attr("number"),
    unlisted: _emberData.default.attr(),
    runAttemptId: _emberData.default.attr(),
    certificateTemplate: _emberData.default.attr(),
    completionThreshold: _emberData.default.attr("number"),
    goodiesThreshold: _emberData.default.attr("number"),
    contestId: _emberData.default.attr(),
    whatsappLink: _emberData.default.attr(),
    discordEnabled: _emberData.default.attr(),
    description: _emberData.default.attr(),
    productId: _emberData.default.attr(),
    totalContents: _emberData.default.attr("number"),
    tier: _emberData.default.attr(),
    max_pause_time: _emberData.default.attr('number'),
    course: _emberData.default.belongsTo("course", {
      inverse: 'runs'
    }),
    sections: _emberData.default.hasMany("sections"),
    user: _emberData.default.belongsTo("user"),
    runAttempts: _emberData.default.hasMany("run-attempt"),
    announcements: _emberData.default.hasMany("announcement"),
    ta: _emberData.default.hasMany("ta"),
    instructors: _emberData.default.hasMany('instructor'),
    runRequests: _emberData.default.hasMany("run-request"),
    shift: _emberData.default.attr(),
    enforce_deadline: _emberData.default.attr(),
    excellenceThreshold: _emberData.default.attr(),
    topRunAttempt: Ember.computed("runAttempts", function () {
      return this.runAttempts.objectAt(0);
    }),
    isStarted: Ember.computed("start", function () {
      return this.start < +new Date() / 1000;
    }),
    startString: Ember.computed("start", function () {
      return new Date(this.start * 1000).toISOString().substring(0, 10);
    }),
    sortedSections: Ember.computed("sections.@each", function () {
      return this.sections.sortBy("id");
    }),
    isAvailable: Ember.computed("enrollmentStart", "enrollmentEnd", function () {
      var enrollmentStart = this.enrollmentStart,
          enrollmentEnd = this.enrollmentEnd,
          now = Math.floor(_moment.default.now() / 1000);
      return enrollmentStart <= now && now < enrollmentEnd && !this.unlisted;
    }),
    totalDuration: Ember.computed("sections.@each.contents.@each", function () {
      return this.sections.reduce(function (acc, section) {
        return acc + section.get("duration");
      }, 0);
    })
  });

  _exports.default = _default;
});