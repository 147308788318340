define("codingblocks-online/pods/attempt/content/quiz/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yv1JAZiZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"border-card border-none bg-grey-darker\"],[10,\"style\",\"min-height: 566px;\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"hideTabNav\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"tab-nav-underline w-100 justify-content-start mb-3\"],[8],[0,\"\\n      \"],[5,\"link-to\",[[12,\"class\",\"tab px-0 mr-5 font-sm bold white\"]],[[\"@route\"],[\"attempt.content.quiz.index\"]],{\"statements\":[[0,\"\\n        About\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[5,\"link-to\",[[12,\"class\",\"tab px-0 mr-5 font-sm bold white\"]],[[\"@route\"],[\"attempt.content.quiz.view\"]],{\"statements\":[[0,\"\\n        Submissions\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/attempt/content/quiz/template.hbs"
    }
  });

  _exports.default = _default;
});