define("codingblocks-online/pods/components/course-library/notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AVGTIdRK",
    "block": "{\"symbols\":[\"note\",\"@runAttempt\",\"@payload\"],\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[4,\"each\",[[23,3,[]]],null,{\"statements\":[[0,\"    \"],[5,\"course-library/notes/row\",[],[[\"@note\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"t-align-c\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"https://minio.codingblocks.com/amoeba/notes-orange.svg\"],[8],[9],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"gradient-text-orange mt-3\"],[8],[0,\"No Notes\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"grey\"],[8],[0,\"\\n      Take important notes while accessing the content\\n    \"],[9],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"button-solid button-orange d-inline-block\"]],[[\"@route\",\"@model\"],[\"attempt\",[23,2,[\"id\"]]]],{\"statements\":[[0,\"\\n      Go To Classroom\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\" \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/course-library/notes/template.hbs"
    }
  });

  _exports.default = _default;
});