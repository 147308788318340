define("codingblocks-online/helpers/find", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.find = find;
  _exports.default = void 0;

  function find(param) {
    var _param = (0, _slicedToArray2.default)(param, 2),
        needle = _param[0],
        haystack = _param[1];

    return haystack.find(function (h) {
      return h == needle;
    });
  }

  var _default = Ember.Helper.helper(find);

  _exports.default = _default;
});