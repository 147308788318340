define("codingblocks-online/pods/attempt/content/quiz/view/quiz-attempt/controller", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    q: 1,
    incorrectQuestionIds: Ember.computed('quizAttempt.result', function () {
      var questions = this.get('quizAttempt.result.questions');
      return questions.filter(function (q) {
        return q.score == 0;
      }).mapBy('id');
    }),
    correctQuestionIds: Ember.computed('quizAttempt.result', function () {
      var questions = this.get('quizAttempt.result.questions');
      return questions.filter(function (q) {
        return q.score > 0;
      }).mapBy('id');
    }),
    incorrectIds: Ember.computed('quizAttempt.result', 'question.id', function () {
      var _this = this;

      var result = this.get('quizAttempt.result');
      var question = result.questions.find(function (el) {
        return el.id == _this.get('question.id');
      });
      return question.incorrectlyAnswered.mapBy('id');
    }),
    correctIds: Ember.computed('quizAttempt.result', 'question.id', function () {
      var _this2 = this;

      var result = this.get('quizAttempt.result');
      var question = result.questions.find(function (el) {
        return el.id == _this2.get('question.id');
      });
      var set = new Set([].concat((0, _toConsumableArray2.default)(question.correctlyAnswered.mapBy('id')), (0, _toConsumableArray2.default)(question.answers)));
      return (0, _toConsumableArray2.default)(set.values());
    }),
    submission: Ember.computed('question', 'quizAttempt', function () {
      var _this3 = this;

      var questionSubmission = this.quizAttempt.submission.find(function (s) {
        return s.id === _this3.question.id;
      });
      return questionSubmission ? questionSubmission['marked-choices'] : [];
    }),
    actions: {
      setQuestion: function setQuestion(index) {
        this.set('q', index + 1);
      },
      nextQuestion: function nextQuestion() {
        this.incrementProperty('q');
      },
      prevQuestion: function prevQuestion() {
        this.decrementProperty('q');
      }
    }
  });

  _exports.default = _default;
});