define("codingblocks-online/pods/components/player/player-notes-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ogEYLqyL",
    "block": "{\"symbols\":[\"note\"],\"statements\":[[4,\"if\",[[24,[\"notes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"class\",\"list-divided border-none\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"notes\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[10,\"class\",\"list-item\"],[8],[0,\"\\n        \"],[5,\"player/player-notes-list-item\",[],[[\"@note\"],[[23,1,[]]]]],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"t-align-c my-5\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"https://minio.codingblocks.com/amoeba/notes-orange.svg\"],[8],[9],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"gradient-text-orange mt-3\"],[8],[0,\"No Notes\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"white\"],[8],[0,\"\\n      Take important notes while accessing the content\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/player/player-notes-list/template.hbs"
    }
  });

  _exports.default = _default;
});