define("codingblocks-online/pods/components/view-submissions-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nBj0pGLe",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"open\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"onClose\",\"translucentOverlay\",\"targetAttachment\",\"containerClass\",\"overlayClass\",\"wrapperClass\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"open\"]]],null]],[[\"value\"],[false]]],true,\"none\",\"centered-scrolling-container\",\"centered-scrolling-overlay\",\"centered-scrolling-wrapper\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"px-2\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[0,\"Submission #\"],[1,[24,[\"submission\",\"id\"]],false],[9],[0,\"\\n    \"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n    \"],[7,\"h3\",true],[8],[0,\"Source Code\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"faded-cont\"],[8],[0,\"\\n      \"],[7,\"pre\",true],[8],[1,[22,\"source\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"button\",false],[12,\"class\",\"button-solid mt-3\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"open\"]]],null]],[[\"value\"],[false]]],[8],[0,\"Close\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "codingblocks-online/pods/components/view-submissions-modal/template.hbs"
    }
  });

  _exports.default = _default;
});