define("codingblocks-online/models/cricket-cup-user-prediction", ["exports", "ember-data", "codingblocks-online/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    farzi: _emberData.default.attr(),
    cricketCupQuestion: _emberData.default.belongsTo('cricketCupQuestion'),
    cricketCupChoice: _emberData.default.belongsTo('cricketCupChoice'),
    cricketCupMatch: _emberData.default.belongsTo('cricketCupMatch')
  });

  _exports.default = _default;
});