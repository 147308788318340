define("codingblocks-online/models/career-track-subscription", ["exports", "@ember-data/model", "ember-data", "moment"], function (_exports, _model, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    startDate: _emberData.default.attr(),
    endDate: _emberData.default.attr(),
    user: _emberData.default.belongsTo('user'),
    isExpired: Ember.computed('endDate', function () {
      return (0, _moment.default)(this.endDate).isBefore((0, _moment.default)());
    }),
    careerTrack: _emberData.default.belongsTo('career-track')
  });

  _exports.default = _default;
});