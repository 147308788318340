define("codingblocks-online/models/upgrade-pack", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr(),
    description: _emberData.default.attr(),
    to: _emberData.default.attr(),
    price: _emberData.default.attr(),
    toRunId: _emberData.default.attr('number'),
    productId: _emberData.default.attr('number'),
    course: _emberData.default.belongsTo('course')
  });

  _exports.default = _default;
});